import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './App.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { MdSportsSoccer } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { auth } from './firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import './HomePageTest.css';
import Flag from 'react-world-flags';
import AdComponent from './AdComponent';


function HomePageTest() {
  const { t } = useTranslation();
  const [authUser, setAuthUser] = useState(null);

  const cards = [
    {
      title: 'English Premier League',
      description: t('epl'),
      linkState: 'English Premier League',
      learnMore: '/epllearnmore',
      flagCode: 'GB_ENG',  // UK Flag for Premier League
    },
    {
      title: 'Scottish Premiership',
      description: 'The top 12 football clubs in Scotland make up the illustrious Scottish Premiership. Will Celtic defend their crown in the 24/25 season?',
      linkState: 'Scottish Premiership',
      learnMore: '/spfllearnmore',
      flagCode: 'GB_SCT',  // Scottish Flag
    },
    {
      title: 'English Championship',
      description: 'With the English Championship\'s fierce competition, will Burnley hold their ground and secure promotion in the 24/25 season?',
      linkState: 'Championship',
      learnMore: '/championshiplearnmore',
      flagCode: 'gb',  // UK Flag for Championship
    },
    {
      title: 'La Liga',
      description: 'With La Liga\'s fierce competition, will Atlético Madrid hold their ground and secure Champions League qualification in the 24/25 season?',
      linkState: 'La Liga',
      learnMore: '/laligalearnmore',
      flagCode: 'es',  // Spain Flag
    },
    {
      title: 'Champions League',
      description: 'In the intense competition of the UEFA Champions League 24/25 season, will traditional powerhouses maintain their dominance?',
      linkState: 'UCL',
      learnMore: '/ucllearnmore',
      flagCode: 'EU',  // European Union Flag
    },
    {
      title: 'Bundesliga',
      description: 'Will traditional giants like Bayern Munich and Borussia Dortmund maintain their dominance in the Bundesliga 24/25 season?',
      linkState: 'Bundesliga',
      learnMore: '/bundesligalearnmore',
      flagCode: 'de',  // German Flag
    },
    {
      title: 'Serie A',
      description: 'With the intense competition in the Serie A 24/25 season, will traditional powerhouses like Juventus maintain their dominance?',
      linkState: 'Serie A',
      learnMore: '/seriealearnmore',
      flagCode: 'it',  // Italian Flag
    },
    {
      title: 'Premier Soccer League',
      description: 'Will traditional powerhouses like Mamelodi Sundowns maintain their dominance in the South African Premier Soccer League 24/25 season?',
      linkState: 'SASoccer',
      learnMore: '/sasoccerlearnmore',
      flagCode: 'za',  
    },
  ];

useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, []);


return (
    <div>
      <div className='App-headerV8'>
      <Navbar />
        <section className="py-3 py-md-5 py-xl-8" style={{ height: '100vh' }}>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <div>
  <script type="text/javascript" 
          src="//cdn.tsyndicate.com/sdk/v1/bi.js" 
          data-ts-spot="c72b65b387304867b13f0012faf13d38" 
          data-ts-width="900" 
          data-ts-height="250" 
          data-ts-extid="{64046}" 
          async 
          defer>
  </script>
</div>

              <h2 className="mb-4 display-5 text-center text-light  wave-text" style={{ fontFamily: 'Lato, sans-serif' }}>Predictify</h2>
              <p className="text-light mb-2 text-center lead fs-4 text-light wave-text" style={{ fontFamily: 'Lato, sans-serif', fontWeight: '300' }}>Welcome to Predictify's Sports Predictor Game!</p>
                {authUser ? (
                  <div>
                    {authUser.emailVerified ? null : null}
                  </div>
                ) : (
                  <p className="text-light mb-5 text-center lead fs-4 d-flex justify-content-center">
                    <span className="text-secondary mb-2 lead fs-4 homehover" style={{ marginRight: '5px' }}>
                      <Link className='homehover' to='/signup' style={{ textDecoration: 'none', color: '#00FF00' }}> Sign Up </Link>
                    </span>
                    now to join in the fun!
                  </p>
                )}
                <hr className="w-50 mx-auto mb-2 mb-xl-9 border-dark-subtle" />
              </div>
            </div>
          </div>

          {/* Swiper Component */}
          <div className="container overflow-hidden" style={{ marginTop: '50px' }}>
          <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              spaceBetween={30}
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true
              }}
              breakpoints={{
                640: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 30 },
                1024: { slidesPerView: 3, spaceBetween: 40 },
              }}
              className="mySwiper"
            >
    {cards.map((card, index) => (
      <SwiperSlide key={index}>
        <div
          className="text-center card-slide border border-white"
          style={{
            borderRadius: '10px',
            overflow: 'hidden',
            position: 'relative',
            backgroundColor: 'transparent !important',
            color: 'white',
            border: '1px solid white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '350px',
            transition: 'all 0.3s ease-in-out',
          }}
          onMo
          onMouseEnter={(e) => e.currentTarget.classList.add('hovered')}
          onMouseLeave={(e) => e.currentTarget.classList.remove('hovered')}
        >
          <div className="px-4 py-3 px-md-6 py-md-4 px-lg-8 py-lg-5">
            <MdSportsSoccer size={58} style={{ color: 'white' }} className="mb-4" /> {/* Updated icon color */}
            <h4 className="text-white">{card.title}</h4> {/* Ensure white text */}
            <p>{card.description}</p> {/* Ensure white text */}
          </div>

          <div className="mb-3 mt-auto">
            <Link to="/joinhuddle" state={{ tournament: card.linkState }}>
              <span className="btn btn-outline-danger text-light snake-border-btn">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <b> {t('playnow')}</b>
              </span>
            </Link>
            <br />
            <Link to={card.learnMore}>
              <span className="btn text-white" style={{ paddingBottom: '5px' }}>
                {t('learnmore')}
              </span>
            </Link>
          </div>

          <Link
            to={card.learnMore}
            className="btn btn-transparent border-0 "
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              width: '70px',
              height: '70px',
              color: 'white',
              textDecoration: 'none',
              borderColor: 'white',
              fontSize: '0.9rem',
              fontWeight: 'bold',
              borderRadius: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Flag code={card.flagCode} height="24" />
          </Link>
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
</div>
        </section>
      <Footer />
      </div>
    </div>
  );
}

export default HomePageTest;
