import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getDatabase, ref, get } from 'firebase/database';
import { auth } from '../firebase';
import ActiveUsers from './ActiveUsers';

function Admin() {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [eplTopPlayers, setEplTopPlayers] = useState([]);
  const [spflTopPlayers, setSpflTopPlayers] = useState([]);
  const [laLigaTopPlayers, setLaLigaTopPlayers] = useState([]);
  const [serieATopPlayers, setSerieATopPlayers] = useState([]);
  const [bundesligaTopPlayers, setBundesligaTopPlayers] = useState([]);
  const [UCLTopPlayers, setUCLTopPlayers] = useState([]);

  const [championshipTopPlayers, setChampionshipTopPlayers] = useState([]);
  const [eplTotalPlayers, setEplTotalPlayers] = useState(0);
  const [serieATotalPlayers, setSerieATotalPlayers] = useState(0);
  const [bundesligaTotalPlayers, setBundesligaTotalPlayers] = useState(0);
  const [UCLTotalPlayers, setUCLTotalPlayers] = useState(0);

const [spflTotalPlayers, setSpflTotalPlayers] = useState(0);
const [laLigaTotalPlayers, setLaLigaTotalPlayers] = useState(0);

const [championshipTotalPlayers, setChampionshipTotalPlayers] = useState(0);
const [mostPopularSport, setMostPopularSport] = useState({ sport: '', count: 0 });
  const [selectedPlayer, setSelectedPlayer] = useState(null); // State for selected player
  const [selectedLeague, setSelectedLeague] = useState(''); 
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [countryCounts, setCountryCounts] = useState({}); // State for country counts
  const [mostPopularCompetition, setMostPopularCompetition] = useState({ name: '', count: 0 });
  const [mostPopularCountry, setMostPopularCountry] = useState({ country: '', count: 0 }); 

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const allowedEmails = ['denis@kingjtech.com', 'denis@atomtech.live', 'support@predictify.app', 'jeremy@fall.com.mt'];
        if (allowedEmails.includes(user.email)) {
          setIsAuthorized(true);
          fetchTopPlayers(); // Fetch top players after authorization
        } else {
          navigate('/'); // Redirect to homepage or error page
        }
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchTopPlayers = async () => {
    try {
      const db = getDatabase();
  
      // Function to fetch and set top players for each league
      const fetchAndSetTopPlayers = async (league, setPlayers, setTotalPlayers, useDifferentPath = false) => {
        // Determine the correct path based on the flag
        const leaderboardPath = useDifferentPath ? `${league}TotalPoints` : `${league}2024TotalPoints`;
  
        const leaderboardRef = ref(db, leaderboardPath);
        const snapshot = await get(leaderboardRef);
        if (snapshot.exists()) {
          const leaderboardData = snapshot.val();
          const sortedPlayers = Object.entries(leaderboardData)
            .filter(([_, data]) => data.totalPoints !== undefined && !isNaN(parseInt(data.totalPoints)))
            .map(([userId, data]) => ({ userId, ...data }))
            .sort((a, b) => b.totalPoints - a.totalPoints);
  
          setPlayers(sortedPlayers.slice(0, 5));
          setTotalPlayers(sortedPlayers.length);
          return sortedPlayers.length;
        } else {
          console.log(`No data available for ${league}`);
          setTotalPlayers(0);
          return 0;
        }
      };
  
      // Fetch top players for each league
      const eplCount = await fetchAndSetTopPlayers('EPL', setEplTopPlayers, setEplTotalPlayers);
      const serieACount = await fetchAndSetTopPlayers('SerieA', setSerieATopPlayers, setSerieATotalPlayers, true); // Serie A has a different path
      const bundesligaCount = await fetchAndSetTopPlayers('Bundesliga', setBundesligaTopPlayers, setBundesligaTotalPlayers, true); // Serie A has a different path
      const UCLCount = await fetchAndSetTopPlayers('UCL', setUCLTopPlayers, setUCLTotalPlayers, true); // Serie A has a different path

      const spflCount = await fetchAndSetTopPlayers('Scottish', setSpflTopPlayers, setSpflTotalPlayers);
      const laLigaCount = await fetchAndSetTopPlayers('LaLiga', setLaLigaTopPlayers, setLaLigaTotalPlayers);
      const championshipCount = await fetchAndSetTopPlayers('Championship', setChampionshipTopPlayers, setChampionshipTotalPlayers);
  
      // Determine the most popular competition
      determineMostPopularCompetition(eplCount, spflCount, championshipCount, laLigaCount, serieACount, bundesligaCount, UCLCount);
  
    } catch (error) {
      console.error("Error fetching top players:", error);
    }
  };
  
  

  const determineMostPopularCompetition = (eplCount, spflCount, championshipCount, laLigaCount, serieACount, bundesligaCount, UCLCount) => {
    let mostPopular = { name: 'EPL', count: eplCount };

    if (spflCount > mostPopular.count) {
      mostPopular = { name: 'SPFL', count: spflCount };
    }
    if (laLigaCount > mostPopular.count) {
      mostPopular = { name: 'La Liga', count: laLigaCount };
    }
    if (serieACount > mostPopular.count) {
      mostPopular = { name: 'Serie A', count: serieACount };
    }
    if (bundesligaCount > mostPopular.count) {
      mostPopular = { name: 'Bundesliga', count: bundesligaCount };
    }
    if (UCLCount > mostPopular.count) {
      mostPopular = { name: 'UCL', count: UCLCount };
    }
    if (championshipCount > mostPopular.count) {
      mostPopular = { name: 'Championship', count: championshipCount };
    }

    setMostPopularCompetition(mostPopular);
  };


  //  total users && new users
  const fetchUsers = async () => {
    try {
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const usersArray = Object.keys(usersData).map((id) => ({
          id,
          ...usersData[id],
        }));
        setUsers(usersArray);
        countNewUsers(usersArray);
        countUsersByCountry(usersArray); // Count users by country
        determineMostPopularSport(usersArray); // Call here

      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const determineMostPopularSport = (usersArray) => {
    const sportCount = {};
  
    // Count occurrences of each sport
    usersArray.forEach(user => {
      const sport = user.selectedSport || 'Unknown';
      sportCount[sport] = (sportCount[sport] || 0) + 1;
    });
  
    // Convert to array and sort by count, highest first
    const sortedSports = Object.entries(sportCount).sort((a, b) => b[1] - a[1]);
  
    let mostPopularSport = { sport: 'Unknown', count: 0 };
  
    // Select the most popular sport, unless it's "Unknown"
    if (sortedSports.length > 0) {
      const [topSport, topCount] = sortedSports[0];
      if (topSport !== 'Unknown' && topSport.trim() !== '') {
        mostPopularSport = { sport: topSport, count: topCount };
      } else if (sortedSports.length > 1) {
        // If "Unknown" is the top sport, select the second most popular
        const [secondSport, secondCount] = sortedSports[1];
        mostPopularSport = { sport: secondSport, count: secondCount };
      }
    }
  
    setMostPopularSport(mostPopularSport);
  };
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const allowedEmails = ['denis@kingjtech.com', 'denis@atomtech.live', 'support@predictify.app', 'jeremy@fall.com.mt'];
        if (allowedEmails.includes(user.email)) {
          setIsAuthorized(true);
          fetchTopPlayers(); // Fetch top players after authorization
          fetchUsers(); // Fetch users after authorization
        } else {
          navigate('/'); // Redirect to homepage or error page
        }
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);
  

  const countNewUsers = (usersArray) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const newUsers = usersArray.filter((user) => {
      const registrationDate = new Date(user.timestamp);
      return (
        registrationDate.getMonth() === currentMonth &&
        registrationDate.getFullYear() === currentYear
      );
    });
    setNewUsersCount(newUsers.length);
  };

  const countUsersByCountry = (usersArray) => {
    const countryCount = {};
    usersArray.forEach(user => {
      const country = user.country || 'Unknown';
      countryCount[country] = (countryCount[country] || 0) + 1;
    });
    setCountryCounts(countryCount);

    // Determine the country with the most users
    const mostPopularCountry = Object.entries(countryCount).reduce((max, [country, count]) => {
      return count > max.count ? { country, count } : max;
    }, { country: 'Unknown', count: 0 });

    setMostPopularCountry(mostPopularCountry); // Update the state
  };


  const getMonthRange = () => {
    const currentDate = new Date();
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthName = monthNames[currentDate.getMonth()];
    const year = currentDate.getFullYear();
    return `1st to the ${new Date(year, currentDate.getMonth() + 1, 0).getDate()} of ${monthName}`;
  };

  //  total users && new users

  const extractUsernameFromDisplayName = (displayName) => {
    if (!displayName) {
      return '';
    }
    // Match the first set of parentheses
    const match = displayName.match(/\(([^)]+)\)/);
    console.log('Match found:', match);  // Debugging line
    return match ? match[1] : displayName;
  };

  const handleViewProfile = (player, league) => {
    setSelectedPlayer(player); 
    setSelectedLeague(league); 
  };

  if (!isAuthorized) {
    return null; 
  }

  return (
    <div>
      <div className="d-flex">
        <Sidebar />
        <div className="flex-grow-1 p-3">
          <h1 className='text-center'>Admin Dashboard</h1>
          <hr className=' w-25 mx-auto' />
              {/* User Statistics */}
              <div className="container mb-3">
                <div className="row">
                  <div className="col-12">
                    <div className="container-fluid bg-light border shadow">
                      <div className="row">
                        <div className="col-12 col-md-4 p-0">
                          <div className="card border-0 bg-transparent">
                            <div className="card-body text-center p-4 p-xxl-5">
                              <h3 className="display-4 fw-bold mb-2">{newUsersCount}</h3>
                              <p className="fs-5 mb-0 text-secondary">
                                <strong>New Users This Month ({getMonthRange()})</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 p-0 border-top border-bottom border-start border-end">
                          <div className="card border-0 bg-transparent">
                            <div className="card-body text-center p-4 p-xxl-5">
                              <h3 className="display-4 fw-bold mb-2">{users.length}</h3>
                              <p className="fs-5 mb-0 text-secondary">Total Users</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 p-0">
                          <div className="card border-0 bg-transparent">
                            <div className="card-body text-center p-4 p-xxl-5">
                              <h3 className="display-4 fw-bold mb-2">{mostPopularCompetition.count} players</h3>
                              <p className="fs-5 mb-0 text-secondary">
                                <strong>Competition with the Most Players: <strong>{mostPopularCompetition.name}</strong> </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*  */}

                     {/* User Statistics */}
                     <div className="container mb-3">
                <div className="row">
                  <div className="col-12">
                    <div className="container-fluid bg-light border shadow">
                      <div className="row">
                        <div className="col-12 col-md-6 p-0">
                          <div className="card border-0 bg-transparent">
                            <div className="card-body text-center p-4 p-xxl-5">
                              <h3 className="display-4 fw-bold mb-2">{mostPopularCountry.count} users</h3>
                              <p className="fs-5 mb-0 text-secondary">
                                <strong>Country with most users - <strong>{mostPopularCountry.country}</strong> </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 p-0 border-top border-bottom border-start border-end">
                          <div className="card border-0 bg-transparent">
                            <div className="card-body text-center p-4 p-xxl-5">
                              <h3 className="display-4 fw-bold mb-2">({mostPopularSport.count} players)</h3>
                              <p className="fs-5 mb-0 text-secondary">Most popular sport - <strong>{mostPopularSport.sport}</strong></p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
                        <p className='text-center'><a href='https://analytics.google.com/analytics/web/#/p455133623/reports/dashboard?params=_u..nav%3Dmaui%26_r.3..selmet%3D%5B%22newUsers%22%5D%26_u.dateOption%3Dlast28Days%26_u.comparisonOption%3Ddisabled&r=business-objectives-examine-user-behavior-overview&ruid=business-objectives-examine-user-behavior-overview,business-objectives,examine-user-behavior&collectionId=business-objectives'>Click here to view full analytics</a></p>
              {/*  */}
              
          <div className='container mx-auto'>
            <h3 className='text-center'><u>Top Players</u></h3>
            <br />
            <div className=" mx-auto">
              <div className="row">
                <div className="col-4">
                  <h4 className='text-center'>Top 5 EPL 24/25</h4>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th className=" text-center">#</th>
                        <th className=" text-center">User</th>
                        <th className=" text-center">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                     {eplTopPlayers.map((player, index) => (
                        <tr key={player.userId}>
                          <td>{index + 1}</td>
                          <td className='text-center'>
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#playerModal"
                              onClick={() => handleViewProfile(player, 'EPL')}>{extractUsernameFromDisplayName(player.userId)}</div></td> 
                              <td className='text-center'>{player.totalPoints}</td>
                        </tr>
                      ))}
                    </tbody>

                  </table>
                  <div className='text-center'>
                  <i>{eplTotalPlayers} total players for EPL</i>
                  </div>
                </div>
                
                <div className="col-4 text-center">
                  <h4>Top 5 Championship 24/25</h4>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th className=" text-center">#</th>
                        <th className=" text-center">User</th>
                        <th className=" text-center">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {championshipTopPlayers.map((player, index) => (
                        <tr key={player.userId}>
                          <td>{index + 1}</td>
                          <td className='text-center'>
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#playerModal"
                              onClick={() => handleViewProfile(player, 'Championship')}>{extractUsernameFromDisplayName(player.userId)}</div></td>                           <td>{player.totalPoints}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='text-center'>
                  <i>{championshipTotalPlayers} total players for Championship</i>
                </div>
                </div>
                <div className="col-4 text-end">
                  <h4 className='text-center'>Top 5 SPFL 24/25</h4>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th className=" text-center">#</th>
                        <th className=" text-center">User</th>
                        <th className=" text-center">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {spflTopPlayers.map((player, index) => (
                        <tr key={player.userId}>
                          <td className=" text-center">{index + 1}</td>
                          <td className='text-center'>
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#playerModal"
                              onClick={() => handleViewProfile(player, 'SPFL')}>{extractUsernameFromDisplayName(player.userId)}</div></td>                           <td className=" text-center">{player.totalPoints}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='text-center'>
                  <i>{spflTotalPlayers} total players for SPFL</i>
                </div>
                </div>
                <div className="col-4 text-end">
                  <h4 className='text-center'>Top 5 La Liga 24/25</h4>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th className=" text-center">#</th>
                        <th className=" text-center">User</th>
                        <th className=" text-center">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {laLigaTopPlayers.map((player, index) => (
                        <tr key={player.userId}>
                          <td className=" text-center">{index + 1}</td>
                          <td className='text-center'>
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#playerModal"
                              onClick={() => handleViewProfile(player, 'La Liga')}>{extractUsernameFromDisplayName(player.userId)}</div></td>                           <td className=" text-center">{player.totalPoints}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='text-center'>
                  <i>{laLigaTotalPlayers} total players for La Liga</i>
                </div>
                </div>
                <div className="col-4 text-end">
                  <h4 className='text-center'>Top 5 Serie A 24/25</h4>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th className=" text-center">#</th>
                        <th className=" text-center">User</th>
                        <th className=" text-center">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {serieATopPlayers.map((player, index) => (
                        <tr key={player.userId}>
                          <td className=" text-center">{index + 1}</td>
                          <td className='text-center'>
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#playerModal"
                              onClick={() => handleViewProfile(player, 'Serie A')}>{extractUsernameFromDisplayName(player.userId)}</div></td>                           <td className=" text-center">{player.totalPoints}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='text-center'>
                  <i>{serieATotalPlayers} total players for Serie A</i>
                </div>
                </div>
                <div className="col-4 text-end">
                  <h4 className='text-center'>Top 5 Bundesliga 24/25</h4>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th className=" text-center">#</th>
                        <th className=" text-center">User</th>
                        <th className=" text-center">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bundesligaTopPlayers.map((player, index) => (
                        <tr key={player.userId}>
                          <td className=" text-center">{index + 1}</td>
                          <td className='text-center'>
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#playerModal"
                              onClick={() => handleViewProfile(player, 'Bundesliga')}>{extractUsernameFromDisplayName(player.userId)}</div></td>                           <td className=" text-center">{player.totalPoints}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='text-center'>
                  <i>{bundesligaTotalPlayers} total players for Bundesliga</i>
                </div>
                </div>
                <div className="col-4 text-end">
                  <h4 className='text-center'>Top 5 UCL 24/25</h4>
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th className=" text-center">#</th>
                        <th className=" text-center">User</th>
                        <th className=" text-center">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {UCLTopPlayers.map((player, index) => (
                        <tr key={player.userId}>
                          <td className=" text-center">{index + 1}</td>
                          <td className='text-center'>
                            <div
                              data-bs-toggle="modal"
                              data-bs-target="#playerModal"
                              onClick={() => handleViewProfile(player, 'UCL')}>{extractUsernameFromDisplayName(player.userId)}</div></td>                           <td className=" text-center">{player.totalPoints}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className='text-center'>
                  <i>{UCLTotalPlayers} total players for UCL</i>
                </div>
                </div>
              </div>
            </div>

     {/* Modal for displaying selected player information */}
     <div className="modal fade" id="playerModal" tabIndex="-1" aria-labelledby="playerModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="playerModalLabel">User Information</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    {selectedPlayer ? (
                      <div>
                        <p><strong>Username:</strong> {extractUsernameFromDisplayName(selectedPlayer.userId)}</p>
                        <p><strong>First Name:</strong> {selectedPlayer.userId.split(' ')[0]}</p>
                        <p><strong>Total {selectedLeague} Points:</strong> {selectedPlayer.totalPoints}</p>
                      </div>
                    ) : (
                      <p>No player selected</p>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
            {/* End of Modal */}
            <div className="d-flex">
        <div className="flex-grow-1 p-3">
          <div className=''>
            <div className="container mt-4">
              <hr />
          
             
            </div>
          </div>
        </div>
      </div>
          </div>
        </div>

      </div>
      
     
    </div>
  );
}

export default Admin;
