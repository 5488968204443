import React, { useState, useEffect, useMemo } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { ref, push, getDatabase, set } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import Navbar from '../Navbar';
import '../App.css'
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list'
import Image123 from '../LogoWhite4.png'
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [username, setUsername] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [promoemail, setPromoEmail] = useState(false);
  const [iAgree, setIAgree] = useState(false);
  const [ageVerified, setAgeVerified] = useState(false);
  const navigate = useNavigate();
  const prohibited = [/* your list of prohibited words */];
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData().map(country => ({ value: country.label, label: country.label })), []);

  const EPL_TEAMS = [
    'Arsenal', 'Aston Villa', 'Bournemouth', 'Brentford', 'Brighton', 'Chelsea',
    'Crystal Palace', 'Everton', 'Fulham', 'Liverpool', 'Luton Town', 'Manchester City',
    'Manchester United', 'Newcastle United', 'Nottingham Forest', 'Sheffield United',
    'Tottenham Hotspur', 'West Ham United', 'Wolverhampton Wanderers', 'Neutral'
  ];

  const RUGBY_TEAMS = [
    'All Blacks', 'Wallabies', 'Springboks', 'England', 'Ireland', 'Wales',
    'Scotland', 'France', 'Italy', 'Argentina', 'Japan', 'Fiji',
    'Samoa', 'Tonga', 'Georgia', 'Romania', 'USA', 'Canada', 'Uruguay', 'Spain' , 'Neutral'
  ];
  
  const AMERICAN_FOOTBALL_TEAMS = [
    'New England Patriots', 'Los Angeles Chargers', 'Buffalo Bills', 'Kansas City Chiefs',
    'Baltimore Ravens', 'Cincinnati Bengals', 'Pittsburgh Steelers', 'Cleveland Browns',
    'Indianapolis Colts', 'Tennessee Titans', 'Jacksonville Jaguars', 'Houston Texans',
    'Miami Dolphins', 'New York Jets', 'New York Giants', 'Dallas Cowboys',
    'Philadelphia Eagles', 'Washington Commanders', 'San Francisco 49ers', 'Seattle Seahawks',
    'Arizona Cardinals', 'Los Angeles Rams', 'Chicago Bears', 'Minnesota Vikings',
    'Detroit Lions', 'Atlanta Falcons', 'New Orleans Saints', 'Carolina Panthers' , 'Neutral'
  ]



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.emailVerified) {
      alert("Your email has been verified! You can now log in.");
    }
  }, [auth]);

  const signup = async () => {
    try {
      const authInstance = getAuth();
      const usernameLowerCase = username.toLowerCase();
  
      for (const word of prohibited) {
        if (usernameLowerCase.includes(word.toLowerCase())) {
          setError('Please do not use prohibited words');
          return;
        }
      }
  
      const userCredential = await createUserWithEmailAndPassword(authInstance, email, password);
      const user = userCredential.user;
  
      await sendEmailVerification(user);
      setVerificationSent(true);
      alert("Verification email sent. Please verify your email before logging in.");
  
      await updateProfile(user, {
        displayName: `${firstname} (${username}) (${lastname}) (${country}) (${selectedSport}) (${selectedTeam}) `,
        emailVerified: false
      });
  
      const phoneNumberAsNumber = parseInt(phoneNumber.replace(/\D/g, ''), 10);
  
      // User data
      const userData = {
        uid: user.uid,
        firstname,
        lastname,
        username,
        email,
        country,
        selectedGender,
        selectedSport,
        selectedTeam,
        phoneNumber: phoneNumberAsNumber,
        promoemail,
        timestamp: new Date().toISOString(),
      };
  
      // Store user data in Firebase under the user's uid
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`); // Use the uid as the key
      await set(userRef, userData); // This will ensure the data is stored under the user's uid
  
    } catch (error) {
      setError(error.message);
      setTimeout(() => setError(''), 5000);
    }
  };

const isFormValid = () => {
  const hasValidUsername = !prohibited.some(word => username.toLowerCase().includes(word.toLowerCase()));

  const valid = (
    firstname &&
    lastname &&
    country &&
    selectedGender &&
    username &&
    email &&
    password &&
    phoneNumber &&
    selectedSport &&
    iAgree &&
    ageVerified &&
    hasValidUsername &&
    !error
  );

  console.log({
    firstname,
    lastname,
    country,
    selectedGender,
    username,
    email,
    password,
    phoneNumber,
    selectedSport,
    iAgree,
    ageVerified,
    error,
    hasValidUsername,
    valid
  });

  return valid;
};




return (
  <div>
    <div className='App-headerV8'>
    <Navbar />
      <section className=" py-3 py-md-5 bg-transparent" style={{ minHeight: '100vh' }}>
        <div className="container bg-transparent">
          <div className="row justify-content-center bg-transparent">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-6 bg-transparent">
              <div className="card border border-light-subtle rounded-3 shadow-sm bg-transparent">
                <div className="card-body p-3 p-md-7 p-xl-7 ">
                  <div className="text-center mb-3">
                    <a href="#!">
                      <img src={Image123} alt="BootstrapBrain Logo" width="160" height="50" />
                    </a>
                  </div>
                  <h2 className="fs-6 fw-normal text-center text-light mb-4">{t('register_description')}</h2>
                  <div>
                    <div className="row gy-2 overflow-hidden ">
                      {/* Input fields and selects */}
                      <div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div 
        className="form-floating mb-3 text-secondary" 
        style={{ backgroundColor: 'transparent' }}
    >
        <input
            type="text"
            className="form-control text-light"
            name="firstName"
            id="firstName"
            placeholder="First Name"
            maxLength={20}
            required
            value={firstname}
            onChange={(e) => setFirstName(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor="firstName"
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            First Name
        </label>
    </div>
</div>



<div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div 
        className="form-floating mb-3"
        style={{ backgroundColor: 'transparent' }}
    >
        <input
            type="text"
            className="form-control text-light"
            name="lastName"
            id="lastName"
            placeholder="Last Name"
            maxLength={20}
            required
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor="lastName"
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            Last Name
        </label>
    </div>
</div>

<div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div className="form-floating mb-3" style={{ backgroundColor: 'transparent' }}>
        <input
            type="text"
            className="form-control text-light"
            name="country"
            id="country"
            placeholder="Country"
            maxLength={20}
            required
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor="country"
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            Country
        </label>
    </div>
</div>



<div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div className="form-floating mb-3" style={{ backgroundColor: 'transparent' }}>
        <input
            type="text"
            className="form-control text-light"
            name="gender"
            id="gender"
            placeholder="Gender"
            maxLength={20}
            required
            value={selectedGender}
            onChange={(e) => setSelectedGender(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor="gender"
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            Gender
        </label>
    </div>
</div>



                      <div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div 
        className="form-floating mb-3"
        style={{ backgroundColor: 'transparent' }}
    >
        <input
            type="text"
            className="form-control text-light"
            name="username"
            id="username"
            placeholder="Username"
            maxLength={20}
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor="username"
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            Username
        </label>
    </div>
</div>

<div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
  <div className="form-floating mb-3" style={{ backgroundColor: 'transparent' }}>
    <input
      type="email"
      className="form-control text-light"
      name="email"
      id="email"
      placeholder="Email"
      required
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      style={{
        backgroundColor: 'transparent', // Make input transparent
        color: 'white', // White text color
        border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
      }}
      onFocus={(e) => {
        // Change label color to grey when input is focused
        e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
        // On focus, change the border color of the label container
        e.target.closest('.form-floating').style.border = '1px solid red';
      }}
      onBlur={(e) => {
        // Check if the input has value and set label color accordingly
        const label = e.target.closest('.form-floating').querySelector('label');
        if (e.target.value.trim() !== "") {
          // If there's text, keep the label grey
          label.style.color = 'grey';
        } else {
          // If there's no text, revert the label color to white
          label.style.color = 'white';
        }
        // On blur, revert the border color of the label container
        e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
      }}
    />
    <label
      htmlFor="email"
      className="form-label bg-transparent text-secondary"
      style={{
        color: 'white', // Initial white text for the label
      }}
    >
      Email
    </label>
  </div>
</div>


                      <div className="col-12 bg-transparent  text-light border-secondary">
                          <div className="input-group mb-3 bg-transparent  text-light border-secondary">
                            <div className="form-floating  bg-transparent  text-light border-secondary">
                              <input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control  bg-transparent text-secondary"
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                              <label htmlFor="password">Password <span className="text-light"></span></label>
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-light"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? 'Hide' : 'Show'}
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6 bg-transparent text-light"> {/* Center the content horizontally */}
  <select
    className="form-control form-control-sm border-outline-secondary mb-2 bg-transparent text-light text-center"
    aria-label="Select Favourite Sport"
    value={selectedSport}
    onChange={(e) => setSelectedSport(e.target.value)}
    required
  >
    <option className='text-dark' value="" disabled>Select Favourite Sport</option>
    <option className='text-dark' value="football">Football</option>
    <option className='text-dark' value="rugby">Rugby</option>
    <option className='text-dark' value="american_football">American Football</option>
  </select>
</div>

<div className='col-lg-6 text-light'>
  <input
    type="text"
    className="form-control form-control-sm border-outline-secondary mb-2 bg-transparent text-light text-center"
    placeholder="Enter Favourite Team"
    value={selectedTeam}
    onChange={(e) => setSelectedTeam(e.target.value)}
    required
    style={{
      color: 'white', // Set text color to white
    }}
  />
</div>

<div className="row justify-content-center mb-2 bg-transparent">
  {/* Left side - Checkboxes */}
  <div className="col-lg-6 ">
    <div className="row ">
      {/* Terms and Conditions */}
      <div className="col-12 form-check mx-auto justify-content-center text-center" >
        <input
          type="checkbox"
          className="form-check-input bg-transparent "
          id="iAgree"
          checked={iAgree}
          onChange={() => setIAgree(!iAgree)}
          required
        />
        
        <label className="form-check-label text-light" htmlFor="iAgree">
          I agree to the <a href="#!">Terms and Conditions</a>
        </label>
      </div>
      
      {/* Age Verification */}
      <div className="col-12 form-check text-light bg-transparent">
        <input
          type="checkbox"
          className="form-check-input bg-transparent"
          id="ageVerified"
          checked={ageVerified}
          onChange={() => setAgeVerified(!ageVerified)}
          required
        />
        <label className="form-check-label text-light" htmlFor="ageVerified">
          I am at least 18 years old
        </label>
      </div>
      
      {/* Promotional Emails */}
      <div className="col-12 form-check text-light">
        <input
          type="checkbox"
          className="form-check-input bg-transparent"
          id="promoemail"
          checked={promoemail}
          onChange={() => setPromoEmail(!promoemail)}
          required
        />
        <label className="form-check-label text-light" htmlFor="promoemail">
          I agree to receive promotional emails
        </label>
      </div>
    </div>
  </div>

  {/* Right side - Phone input and Button */}
  <div className="col-lg-6 bg-transparent">
    <div className="row justify-content-center mb-2 bg-transparent">
      <PhoneInput
        country={'mt'} // Default country
        value={phoneNumber}
        onChange={setPhoneNumber}
        inputClassName="mx-auto"
        required
        className="bg-transparent"
      />
    </div>
    {/* Button below the Phone Input */}
    <div className='col-lg-12 text-center'>
      <Link to="/login">
        <button
          className='btn btn-outline-success btn-sm form-control text-light'
          style={{
            width: '100%',  // Make the button the same width as other form inputs
            backgroundColor: 'transparent',  // Make background transparent to match other inputs
          }}
        >
          Already have an account?
        </button>
      </Link>
    </div>
  </div>
</div>



                      <div className="col-12">
                        <button
                          className="btn btn-purple w-100 mb-2"
                          onClick={signup}
                          disabled={!isFormValid()}
                        >
                          <div className='text-light'>Sign Up</div>
                        </button>
                        {error && <p className="text-danger">{error}</p>}
                        {verificationSent && (
                        <div>
                          <div className="alert alert-success text-center" role="alert">
                            <p>Verification email sent. Please verify your email before signing in.</p>
                          </div>
                          <div className="alert alert-warning text-center" role="alert">
                            <p>Please refresh the page once you have done this</p>
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <Footer />
    </div>
  </div>
);
};

export default SignUp;