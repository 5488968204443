import Sundowns from '../Team-Kits-Images/Sundowns.png'
import Pirates from '../Team-Kits-Images/Pirates.png'
import Arrows from '../Team-Kits-Images/Arrows.png'
import Polokwane from '../Team-Kits-Images/Polokwane.png'
import Richards from '../Team-Kits-Images/Richards.png'
import Kaizer from '../Team-Kits-Images/Kaizer.png'
import Sekh from '../Team-Kits-Images/Sekh.png'
import Chippa from '../Team-Kits-Images/Chippa.png'
import Royal from '../Team-Kits-Images/Royal.png'
import Cape from '../Team-Kits-Images/Cape.png'
import Supersport from '../Team-Kits-Images/Supersport.png'
import Amazulu from '../Team-Kits-Images/Amazulu.png'
import Galaxy from '../Team-Kits-Images/Galaxy.png'
import Stell from '../Team-Kits-Images/Stell.png'
import Magesi from '../Team-Kits-Images/Magesi.png'
import Marumo from '../Team-Kits-Images/Margumo.png'



import { useTranslation } from 'react-i18next';
import Standings from './Standings'
import { runTransaction } from 'firebase/firestore'


import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import BundesligaStandingsPicks from './BundesligaStandingsPicks'
import SASoccerStandings from './SASoccerStandings'
import SASoccerStandingsPicks from './SASoccerStandingsPicks'
import AdComponent from '../AdComponent'
 
function SASoccerPicks() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); // Initialize to the current round, e.g., round 8
  const [buttonsToShow, setButtonsToShow] = useState(0);

  const fetchTotalPoints = async (displayName) => {
    try {
      const db = getDatabase();
      const totalPointsRef = ref(db, `SASoccerTotalPoints/${displayName}`);
      const totalPointsSnapshot = await get(totalPointsRef);
      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  const fetchPicks = async (displayName) => {
    try {
      const db = getDatabase();
      const picksRef = ref(db, `SASoccerPicks/${displayName}`);
      const totalPointsRef = ref(db, `SASoccerTotalPoints/${displayName}`);

      const [picksSnapshot, totalPointsSnapshot] = await Promise.all([
        get(picksRef),
        get(totalPointsRef)
      ]);

      const picksData = picksSnapshot.val() || {};
      const userPicks = picksData || {};
      setInputsData(userPicks);
      setDisplayName(displayName);

      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);

      const pointsData = {};
      Object.keys(userPicks).forEach(fixtureId => {
        pointsData[fixtureId] = userPicks[fixtureId].points;
      });
      setFixturePoints(pointsData);

      // Start point incrementing intervals for the fetched picks
      Object.keys(userPicks).forEach(fixtureId => {
        if (!intervalRefs.current[fixtureId]) {
          startPointsIncrement(fixtureId);
        }
      });
    } catch (error) {
      console.error("Error fetching picks:", error);
    }
  };

  const [dateRanges] = useState([
    { start: '2024-09-01', end: '2024-09-30' },  // Matchweek 1 (September 2024)
    { start: '2024-10-01', end: '2024-10-31' },  // Matchweek 2 (October 2024)
    { start: '2024-11-01', end: '2024-11-30' },  // Matchweek 3 (November 2024)
    { start: '2024-12-01', end: '2024-12-31' },  // Matchweek 4 (December 2024)
    { start: '2025-01-01', end: '2025-01-31' },  // Matchweek 5 (January 2025)
    { start: '2025-02-01', end: '2025-02-28' },  // Matchweek 6 (February 2025)
    { start: '2025-03-01', end: '2025-03-31' },  // Matchweek 7 (March 2025)
    { start: '2025-04-01', end: '2025-04-30' },  // Matchweek 8 (April 2025)
    { start: '2025-05-01', end: '2025-05-31' },  // Matchweek 9 (May 2025)
]);


useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);


  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    if (user) {
      const displayName = user.displayName || 'Anonymous';
      fetchPicks(displayName);
      fetchTotalPoints(displayName);
    }
  }, [user]);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/288/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  


useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);


const awardPointsForAllUsers = async (fixtureId) => {
  try {
    const db = getDatabase();
    const fixtureRef = ref(db, `fixtures/${fixtureId}`);
    const fixtureSnapshot = await get(fixtureRef);
    const fixtureData = fixtureSnapshot.val();

    console.log("Fixture Data:", fixtureData); // Debug: Log fixture data

    if (fixtureData && fixtureData.fixture.status.short === "FT") {
      const homeGoals = fixtureData.score.fulltime.home;
      const awayGoals = fixtureData.score.fulltime.away;

      const picksRef = ref(db, `SASoccerPicks`);
      const picksSnapshot = await get(picksRef);
      const allUserPicks = picksSnapshot.val() || {};

      Object.keys(allUserPicks).forEach(async (userId) => {
        const userPicks = allUserPicks[userId];
        const userPick = userPicks[fixtureId];

        if (userPick && !userPick.pointsAwarded) {
          const { home: predictedHomeGoals, away: predictedAwayGoals } = userPick;

          let points = 0;

          if (predictedHomeGoals == homeGoals && predictedAwayGoals == awayGoals) {
            points = 3;
          } else if (
            (predictedHomeGoals > predictedAwayGoals && homeGoals > awayGoals) ||
            (predictedHomeGoals < predictedAwayGoals && homeGoals < awayGoals) ||
            (predictedHomeGoals == predictedAwayGoals && homeGoals == awayGoals)
          ) {
            points = 1;
          }

          console.log(`Awarding ${points} points to user ${userId} for fixture ${fixtureId}`); // Debug: Log points awarded

          userPicks[fixtureId] = {
            ...userPick,
         
          };

          const userTotalPointsRef = ref(db, `SASoccerTotalPoints/${userId}`);
          const userTotalPointsSnapshot = await get(userTotalPointsRef);
          const userTotalPoints = userTotalPointsSnapshot.val() || { totalPoints: 0 };

          await set(userTotalPointsRef, {
            totalPoints: userTotalPoints.totalPoints + points,
          });

          await set(ref(db, `SASoccerPicks/${userId}`), userPicks);
        }
      });
    }
  } catch (error) {
    console.error("Error awarding points:", error);
  }
};



const startPointsIncrement = (fixtureId) => {
  intervalRefs.current[fixtureId] = setInterval(() => {
    awardPointsForAllUsers(fixtureId);
  }, 60000); // Check every 60 seconds
};

const stopPointsIncrement = (fixtureId) => {
  if (intervalRefs.current[fixtureId]) {
    clearInterval(intervalRefs.current[fixtureId]);
    delete intervalRefs.current[fixtureId];
  }
};

// Ensure that this function is called every 60 seconds to check for completed fixtures
useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);

const handleInputChange = (fixtureId, team, value) => {
    setInputsData(prevInputsData => ({
      ...prevInputsData,
      [fixtureId]: {
        ...prevInputsData[fixtureId],
        [team]: value,
  
      }
    }));
  };

  const handleSubmit = async () => {
    if (userId) {
      const db = getDatabase();
      const picksRef = ref(db, `SASoccerPicks/${displayName}`);
      const totalPointsRef = ref(db, `SASoccerTotalPoints/${displayName}`);
  
      let userDisplayName = user.displayName || 'Anonymous';
  
      // Extract user ID within parentheses
      const match = userDisplayName.match(/\(([^)]+)\)/);
      if (match && match[1]) {
        userDisplayName = match[1];
      }
  
      try {
        // Fetch existing picks from the backend
        const picksSnapshot = await get(picksRef);
        const existingPicksData = picksSnapshot.val() || {};
  
        // Debug: Log the current inputsData before filtering
        console.log("inputsData before filtering:", inputsData);
  
        // Filter inputsData to only include fixtures where a valid prediction is made
        const filteredInputsData = Object.keys(inputsData).reduce((acc, fixtureId) => {
          const fixturePrediction = inputsData[fixtureId];
  
          // Check if home or away prediction is made (0 is valid, undefined is not)
          if (fixturePrediction.home !== undefined || fixturePrediction.away !== undefined) {
            acc[fixtureId] = {
              home: fixturePrediction.home !== undefined ? fixturePrediction.home : "",
              away: fixturePrediction.away !== undefined ? fixturePrediction.away : ""
            };
          }
          return acc;
        }, {});
  
        // Debug: Log the filtered inputsData after filtering
        console.log("filteredInputsData:", filteredInputsData);
  
        // Check if any predictions are available for submission
        if (Object.keys(filteredInputsData).length === 0) {
          console.log("No predictions made, nothing to submit.");
          return;  // Exit early if there are no valid predictions
        }
  
        // Merge new filtered picks with existing picks
        const mergedPicks = {
          ...existingPicksData,
          ...filteredInputsData
        };
  
        // Debug: Log the merged data being sent to the backend
        console.log("Merged picks data being submitted:", mergedPicks);
  
        // Submit the merged picks to the backend
        await set(picksRef, mergedPicks);
  
        // Check if totalPoints entry exists, if not, initialize it
        const totalPointsSnapshot = await get(totalPointsRef);
        if (!totalPointsSnapshot.exists()) {
          await set(totalPointsRef, { totalPoints: 0 });
        }
  
        setSent(true);
        setTimeout(() => setSent(false), 15000);
        setConfetti(true);
        setTimeout(() => setConfetti(false), 5000);
  
        // Start points incrementing for all fixtures with a prediction
        Object.keys(filteredInputsData).forEach(fixtureId => {
          startPointsIncrement(fixtureId);
        });
      } catch (error) {
        console.error('Error submitting picks:', error);
        setError(true);
        setTimeout(() => setError(false), 5000);
      }
    }
  };
  

  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamLogos = {
    'Mamelodi Sundowns': Sundowns,
    'Orlando Pirates': Pirates,
    'Golden Arrows': Arrows,
    'Polokwane City': Polokwane,
    'Richards Bay': Richards,
    'Kaizer Chiefs': Kaizer,
    'Sekhukhune United': Sekh,
    'Chippa United': Chippa,
    'Royal AM': Royal,
    'Cape Town City': Cape,
    'Supersport United': Supersport,
    'Amazulu': Amazulu,
    'TS Galaxy': Galaxy,
    'Stellenbosch': Stell,
    'Magesi': Magesi,
    'Marumo Gallants': Marumo
};
  
const teamInitials = {
    'Mamelodi Sundowns': 'MSFC',
    'Orlando Pirates': 'OPFC',
    'Golden Arrows': 'GAFC',
    'Polokwane City': 'PFC',
    'Richards Bay': 'RBFC',
    'Kaizer Chiefs': 'KCFC',
    'Sekhukhune United': 'SUFC',
    'Chippa United': 'CUFC',
    'Royal AM': 'RAM',
    'Cape Town City': 'CTC',
    'Supersport United': 'SSU',
    'Amazulu': 'AFC',
    'TS Galaxy': 'TSG',
    'Stellenbosch': 'SFC',
    'Magesi': 'MFC',
    'Marumo Gallants': 'MGFC'
};

  
const renderTeamLogo = (teamName) => {
  const logo = teamLogos[teamName];
  if (logo) {
    return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
  }
  return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
};

const renderTeamInitials = (teamName) => {
  return teamInitials[teamName] || teamName;
};
const stadiums = {
  'Mamelodi Sundowns': 'Stadium: Loftus Versfeld Stadium',
  'Orlando Pirates': 'Stadium: Orlando Stadium',
  'Golden Arrows': 'Stadium: Princess Magogo Stadium',
  'Polokwane City': 'Stadium: Peter Mokaba Stadium',
  'Richards Bay': 'Stadium: Richards Bay Stadium',
  'Kaizer Chiefs': 'Stadium: FNB Stadium',
  'Sekhukhune United': 'Stadium: Peter Mokaba Stadium',
  'Chippa United': 'Stadium: Nelson Mandela Bay Stadium',
  'Royal AM': 'Stadium: Chatsworth Stadium',
  'Cape Town City': 'Stadium: Cape Town Stadium',
  'Supersport United': 'Stadium: Lucas Masterpieces Moripe Stadium',
  'Amazulu': 'Stadium: Moses Mabhida Stadium',
  'TS Galaxy': 'Stadium: Mbombela Stadium',
  'Stellenbosch': 'Stadium: Danie Craven Stadium',
  'Magesi': 'Stadium: Old Peter Mokaba Stadium',
  'Marumo Gallants': 'Stadium: Peter Mokaba Stadium',
};



  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

  // Handle Previous Button Click
  

  
  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(`Round ${index + 1}`);
    handlePageChange(index + 1);
  };

  // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  useEffect(() => {
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonsToShow = Math.floor(containerWidth / 125); // Adjust this value based on your design
        setButtonsToShow(Math.min(8, calculatedButtonsToShow));
        calculateStartIndex(currentPage, Math.min(8, calculatedButtonsToShow));
      }
    };

    calculateButtonsToShow();

    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, [currentPage]);

  const calculateStartIndex = (currentPage, buttonsToShow) => {
    let minIndex = Math.max(0, currentPage - Math.floor(buttonsToShow / 2));
    minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
    setStartIndex(minIndex);
  };

  const handleMatchdaySelectSmooth = (index) => {
    setSelectedMatchdayIndex(index);
    handleMatchdaySelect(index);
  };

  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -((100 / buttonsToShow) + 10), // Move left by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + buttonsToShow < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: (100 / buttonsToShow) + 10, // Move right by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex + 1);
    }
  };

  return (
    <div>
    <div className='App-headerV8'>
    <Navbar />
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif'}}>Premier Soccer League Picks</h2>
      <div className="text-light text-center" style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
  {/* Mobile Dropdown */}
  <div className="dropdown d-inline-block d-md-none">
    <button className="btn btn-purple dropdown-toggle" type="button" id="groupStageDropdown" data-bs-toggle="dropdown" aria-expanded="true">
      {selectedMatchday}
    </button>
    <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
      <ul className="list-group">
        {dateRanges.map((range, index) => (
          <li key={index} className="list-group-item">
            <button
              className={`dropdown-item ${selectedMatchday === `Round ${index + 1}` ? 'btn-purple text-light' : ''}`}
              onClick={() => handleMatchdaySelect(index)}
              style={{ width: '100%' }}
            >
              Round {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>

  {/* Horizontal Scroll for Large Screens */}
  <div className="container">
    <div className="d-none d-md-flex align-items-center justify-content-center" style={{ width: '100%', marginLeft: '3rem' }} ref={containerRef}>
      <button className="btn btn-outline-light" onClick={handleLeftArrowClick} disabled={startIndex === 0} style={{ width: '5%', marginRight: '1rem' }}>
        &lt;
      </button>

      <div
        className="rounds-container"
        ref={roundsContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '70%', // Set to allow space for buttons
          marginLeft: '1rem',
          scrollBehavior: 'smooth',
        }}
      >
        {dateRanges.slice(startIndex, startIndex + buttonsToShow).map((range, index) => (
          <button
            key={startIndex + index}
            className={`btn me-2 ${selectedMatchday === `Round ${startIndex + index + 1}` ? 'btn-purple' : 'btn-outline-light'}`}
            style={{ width: `calc(${(100 / buttonsToShow)}% - 9px)` }}
            onClick={() => handleMatchdaySelect(startIndex + index)}
          >
            Round {startIndex + index + 1}
          </button>
        ))}
      </div>

      <button className="btn btn-outline-light" onClick={handleRightArrowClick} disabled={startIndex + buttonsToShow >= dateRanges.length} style={{ width: '5%', marginLeft: '1rem' }}>
        &gt;
      </button>
    </div>
  </div>
</div>

 
      <div className='container'>
        <div className='row'>
          {/* Standings Card for large screens */}
          <div className='col-lg-5 d-none d-lg-flex align-items-start justify-content-end standings-card-margin bg-transparent' style={{ paddingTop: '5px' }}>
  <div className='card ' style={{ backgroundColor: 'transparent', border: 'none' }}> {/* Makes the card background and border transparent */}
    <div className='card-body'>
      <h5 className='card-title'><SASoccerStandingsPicks /></h5>
    </div>
  </div>
</div>

  
          {/* Main content area */}
          <div className='col-lg-7 col-12 bg-transparent ' style={{ backgroundColor: 'transparent', border: 'none', paddingTop: '5px' }}>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
  
              let points = 0;
  
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
  
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-1 card-hover bg-transparent border-light'>
                <div className='card-body text-center mb-1 border-light'>
                  <h5 className='card-title mb-3 text-light'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
              
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
                    <h6 className='card-title mb-3 text-light'>{stadiums[fixture.teams.home.name]}</h6>
                  )}
              
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center text-light">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5 className='text-light'>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
              
                    <div className="col text-center padtopinput border-secondary">
  <div className='form-group text-center' style={{ borderColor: '#9370DB' }}>
    <select
      value={
        fixture.fixture.status.short === 'FT'
          ? (inputsData[fixture.fixture.id]?.home ?? '')  // If finished, show the pick or no pick
          : (inputsData[fixture.fixture.id]?.home ?? '')
      }
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'home', value);
        } else {
          handleInputChange(fixture.fixture.id, 'home', '');
        }
      }}
      className={`form-control text-center ${fixture.fixture.status.short === 'FT' ? 'bg-secondary text-light' : ''}`}
      disabled={fixture.fixture.status.short === 'FT'}  // Disable select if the fixture is finished
      style={{
        textAlign: 'center',
        textAlignLast: 'center',
        borderColor: '#9370DB' // Apply border color
      }}
    >
      {fixture.fixture.status.short === 'FT' ? (
        <option value="">
          {inputsData[fixture.fixture.id]?.home
            ? inputsData[fixture.fixture.id]?.home
            : window.innerWidth <= 768 // Adjust text for mobile devices
              ? 'Pick'
              : 'No pick'}
        </option>
      ) : (
        <>
          <option value="">Pick</option>
          {[...Array(11).keys()].map(num => (
            <option key={num} value={num}>{num}</option>
          ))}
        </>
      )}
    </select>
  </div>
</div>

<div className="col text-center padtopinput border-secondary">
  <div className='form-group text-center' style={{ borderColor: '#9370DB' }}>
    <select
      value={
        fixture.fixture.status.short === 'FT'
          ? (inputsData[fixture.fixture.id]?.away ?? '')  // If finished, show the pick or no pick
          : (inputsData[fixture.fixture.id]?.away ?? '')
      }
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'away', value);
        } else {
          handleInputChange(fixture.fixture.id, 'away', '');
        }
      }}
      className={`form-control text-center ${fixture.fixture.status.short === 'FT' ? 'bg-secondary text-light' : ''}`}
      disabled={fixture.fixture.status.short === 'FT'}  // Disable select if the fixture is finished
      style={{
        textAlign: 'center',
        textAlignLast: 'center',
        borderColor: '#9370DB' // Apply border color
      }}
    >
      {fixture.fixture.status.short === 'FT' ? (
        <option value="">
          {inputsData[fixture.fixture.id]?.away
            ? inputsData[fixture.fixture.id]?.away
            : window.innerWidth <= 768 // Adjust text for mobile devices
              ? 'Pick'
              : 'No pick'}
        </option>
      ) : (
        <>
          <option value="">Pick</option>
          {[...Array(11).keys()].map(num => (
            <option key={num} value={num}>{num}</option>
          ))}
        </>
      )}
    </select>
  </div>
</div>


              
                    <div className="col text-center d-flex flex-column align-items-center text-light">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
              
                  <div className='text-light'>Result: {fixture.fixture.status.short === 'NS' ? 'TBA' : `${fixture.goals.home} - ${fixture.goals.away}`}</div>
              
                  {/* Display the points scored for this fixture */}
                  {fixture.fixture.status.short === 'FT' && (
                    <p className={points === 1 ? 'text-warning' : points === 3 ? 'text-success' : 'text-light'}>
                      You scored <b>{points}</b> {points === 1 ? 'point' : 'points'} for this game
                    </p>
                  )}
                </div>
              
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
                  style={{
                    backgroundColor: '#9370DB',
                    borderRadius: '5px',
                    borderBottomLeftRadius: '0px', // Set bottom-left corner radius to 0
                    borderBottomRightRadius: '0px' // Set bottom-right corner radius to 0
                  }}
                >
                  <p className="text-center mb-2 btn-sm text-bold text-light"  
                    style={{
                      color: '#fff',
                      paddingTop: '4px',
                      borderTopLeftRadius: '1px'
                    }}
                  >
                    Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}
                  </p>
                </div>
              </div>
              
              
              
              );
            })}
  
            <div className='text-center' style={{ paddingBottom: '10px' }}>
              <button onClick={handleSubmit} className='btn btn-purple text-center'>Submit Picks</button>
              {confetti && <Confetti />}
            </div>
  
            <div className='container text-center padtop' style={{ paddingBottom: '10px' }}>
              <p className='text-light'>Total points: <b>{totalPoints}</b></p>
            </div>
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
            <script type="text/javascript" src="//cdn.tsyndicate.com/sdk/v1/bi.js" data-ts-spot="c72b65b387304867b13f0012faf13d38" data-ts-width="900" data-ts-height="250" data-ts-extid="{extid}" async defer></script>  </div>
            {sent && (
              <>
                <div className='alert alert-success Nav-HeaderV8 text-center'>
                  Your picks have been sent!
                </div>
                {confetti && <Confetti gravity={-5.5} />}
              </>
            )}
            {error && <p className='text-danger'>Error submitting picks!</p>}
          </div>
       
          {/* Standings Card for small screens */}
          <div className='col-12 d-lg-none mb-3' >
            <div className='card' style={{ backgroundColor: 'transparent', border: 'none' }}>
              <div className='card-body'>
                <h5 className='card-title'><SASoccerStandingsPicks /></h5>
              </div>
            </div>
          </div>

  

        </div>
      </div>
    <Footer />
    </div>
  </div>
  
  
  );
}

export default SASoccerPicks;
