import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Privacy() {

  const { t } = useTranslation();

  return (
    <div>
      <div className='App-headerV9'>

      <Navbar />
      </div>
      <div className=''>
        <div className='container'>
        <div className='mb-6'></div>

  <b>Privacy Policy for Predictify</b>
  <ul />
  <b>Introduction</b>
  <ul />
  <p>Predictify ("we," "our," "us") values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our services.</p>
  <b>Information We Collect</b>
  <ul />
  <p>1. Personal Information: When you sign up or contact us, we may collect personal details such as your name, email address, phone number, and payment information.</p>
  <p>2. Usage Data: We automatically collect information on how you interact with our services, including your IP address, browser type, pages visited, and the time and date of your visits.</p>
  <b>How We Use Your Information</b>
  <ul />
  <p>1. To provide and maintain our services: Ensuring smooth operation and improvements.</p>
  <p>2. To communicate with you: Sending updates, support, and promotional materials.</p>
  <p>3. To improve our services: Using collected data to understand and enhance user experience.</p>
  <p>4. For security purposes: Monitoring and ensuring the security of our services.</p>
  <b>Sharing Your Information</b>
  <ul />
  <p>We may share your information with third parties in the following situations:</p>
  <p>1. Service Providers: To help us operate and improve our services.</p>
  <p>2. Legal Requirements: If required by law or to protect our rights.</p>
  <p>3. Business Transfers: In the event of a merger or sale of assets.</p>
  <b>Data Security</b>
  <ul />
  <p>We use standard security measures to protect your information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.</p>
  <b>Your Rights</b>
  <ul />
  <p>You have the right to access, correct, or delete your personal information. To exercise these rights, please contact us at support@predictify.app.</p>
  <b>Contact Us</b>
  <ul />
  <p>If you have any questions about this Privacy Policy, please contact us at:</p>
  <p />
  <p>support@predictify.app</p>
  
  <b>Changes to This Privacy Policy</b>
  <ul />
  <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
  <p>Last updated: 12/06/2024</p>
  <ul />
  </div>
  <ul />
</div>
<br />

<div className='App-headerV9'>

    <Footer />
</div>
    </div>

  )
}

export default Privacy