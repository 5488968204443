import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import BayernMunich from '../Team-Kits-Images/BayernMunich.png'
import Leverkusen from '../Team-Kits-Images/Leverkusen.png'
import Freiburg from '../Team-Kits-Images/Freiburg.png'
import Frankfurt from '../Team-Kits-Images/Frankfurt.png'
import Berlin from '../Team-Kits-Images/Berlin.png'
import Leipzig from '../Team-Kits-Images/Leipzig.png'
import Stuttgart from '../Team-Kits-Images/Stuttgart.png'
import Dortmund from '../Team-Kits-Images/Dortmund.png'
import Heidenheim from '../Team-Kits-Images/Heidenheim.png'
import Mainz from '../Team-Kits-Images/Mainz.png'
import Bremen from '../Team-Kits-Images/Bremen.png'
import Augsburg from '../Team-Kits-Images/Augsburg.png'
import Wolfsburg from '../Team-Kits-Images/Wolfsburg.png'
import Monch from '../Team-Kits-Images/Monch.png'
import Hoffenheim from '../Team-Kits-Images/Hoffenheim.png'
import Bochum from '../Team-Kits-Images/Bochum.png'
import Pauli from '../Team-Kits-Images/Pauli.png'
import Kiel from '../Team-Kits-Images/Kiel.png'
import 'bootstrap/dist/css/bootstrap.min.css';

function BundesligaStandingsPicks() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal visibility
  const [teamStats, setTeamStats] = useState(null);
  const [formArray, setFormArray] = useState([]); // Initialize formArray
  const [nextFixture, setNextFixture] = useState(null);
  const [nextFixtures, setNextFixtures] = useState({});
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/78/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    fixtures.forEach((fixture) => {
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      
      if (!standingsMap[homeTeam]) {
        standingsMap[homeTeam] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, homeWins: 0, awayWins: 0, homeGamesPlayed: 0, awayGamesPlayed: 0 };
      }
      if (!standingsMap[awayTeam]) {
        standingsMap[awayTeam] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, homeWins: 0, awayWins: 0, homeGamesPlayed: 0, awayGamesPlayed: 0 };
      }
  
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return;
      }
  
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
      standingsMap[homeTeam].homeGamesPlayed += 1;
      standingsMap[awayTeam].awayGamesPlayed += 1;
  
      if (homeGoals > awayGoals) {
        standingsMap[homeTeam].wins += 1;
        standingsMap[homeTeam].homeWins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        standingsMap[awayTeam].wins += 1;
        standingsMap[awayTeam].awayWins += 1;
        standingsMap[homeTeam].losses += 1;
      } else {
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points = standingsMap[homeTeam].wins * 3 + standingsMap[homeTeam].draws;
      standingsMap[awayTeam].points = standingsMap[awayTeam].wins * 3 + standingsMap[awayTeam].draws;
    });
  
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points);
  
    return standingsArray;
  };
  
  const openModal = async (teamName) => {
    if (!standings || standings.length === 0) {
      console.warn("Standings data is not available yet.");
      return; // Prevent opening modal if standings are not loaded
    }
  
    setSelectedTeam(teamName);
  
    try {
      setLoading(true);
  
      const response = await fetch('https://iga-sport.azurewebsites.net/api/league/78/fixtures/season/2024', {
        headers: {
          'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg==',
        },
      });
  
      const data = await response.json();
  
      const teamFixtures = data.filter(
        (fixture) =>
          (fixture.teams.home.name === teamName || fixture.teams.away.name === teamName) &&
          fixture.fixture.status.short === 'FT'
      );
  
      teamFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
  
      const lastFiveGames = teamFixtures.slice(-5);
  
      const formArray = lastFiveGames.map((game) => {
        const isHome = game.teams.home.name === teamName;
        const goalsFor = isHome ? game.goals.home : game.goals.away;
        const goalsAgainst = isHome ? game.goals.away : game.goals.home;
  
        if (goalsFor > goalsAgainst) return 'W';
        if (goalsFor === goalsAgainst) return 'D';
        return 'L';
      });
  
      setFormArray(formArray);
  
      // Safely retrieve team stats
      const selectedTeamStats = standings.find((team) => team.team === teamName);
  
      if (selectedTeamStats) {
        setTeamStats(selectedTeamStats);
      } else {
        console.warn("Team stats not found in standings.");
        setTeamStats(null); // Fallback
      }
  
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching last 5 games:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const closeModal = () => {
    setIsModalOpen(false); // Hide modal
  };

  const teamKits = {
    'Bayern München': BayernMunich,
    'Bayer Leverkusen': Leverkusen,
    'SC Freiburg': Freiburg,
    'Eintracht Frankfurt': Frankfurt,
    'Union Berlin': Berlin,
    'RB Leipzig': Leipzig,
    'VfB Stuttgart': Stuttgart,
    'Borussia Dortmund': Dortmund,
    '1. FC Heidenheim': Heidenheim,
    'FSV Mainz 05': Mainz,
    'Werder Bremen': Bremen,
    'FC Augsburg': Augsburg,
    'VfL Wolfsburg': Wolfsburg,
    'Borussia Mönchengladbach': Monch,
    '1899 Hoffenheim': Hoffenheim,
    'VfL Bochum': Bochum,
    'FC St. Pauli': Pauli,
    'Holstein Kiel': Kiel
  };

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league-teams-stats/78/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        const Data = data.find(team => team.id === 42);
        if (Data) {
          setTeamStats(Data);
        } else {
          console.log(' data not found');
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    const fetchNextFixture = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league/78/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            })
        if (!response.ok) {
          throw new Error('Failed to fetch fixtures');
        }
        const fixturesData = await response.json();
        console.log('All fixtures:', fixturesData);

        const Fixtures = fixturesData.filter(fixture => {
          return fixture.teams.home.id === 42 || fixture.teams.away.id === 42;
        });
        console.log('Fixtures:', Fixtures);

        // Sort the fixtures by date in ascending order
        Fixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Find the first upcoming fixture
        const upcomingFixture = Fixtures.find(fixture => {
          const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
          const currentDate = new Date(); // Get current date
          return fixtureDate > currentDate; // Return true if fixture date is in the future
        });

        setNextFixture(upcomingFixture);
      } catch (error) {
        console.error('Error fetching fixtures:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
    fetchNextFixture();
  }, []);
  
  const form = teamStats?.form || '';

  const lastFiveMatches = formArray.slice(-5);

  const getColorForMatch = (result) => {
    switch (result) {
      case 'W':
        return 'green';  // Green for Win
      case 'D':
        return '#f49f05'; // Yellow for Draw
      case 'L':
        return 'red';    // Red for Loss
      default:
        return 'gray';   // Fallback color
    }
  };
  
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  return (
    <div className="container">
      <div className="">
        <h5 className="text-light">Standings</h5>
        <hr className="w-25 text-light" />
      </div>
      <div className="table-responsive" style={{ backgroundColor: 'transparent' }}>
        {loading ? (
          <h2 className="text-center text-dark"><Spinner /></h2>
        ) : (
          <table className="table transparent-table" style={{ backgroundColor: 'transparent' }}>
            <thead>
              <tr>
                <th className='text-light'>#</th>
                <th className='text-light'>Team</th>
                <th className='text-light'>P</th>
                <th className='text-light'>W</th>
                <th className='text-light'>D</th>
                <th className='text-light'>L</th>
                <th className='text-light'>Pts</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
                  <th scope="row" className={index < 4 ? 'text-primary' : index >= standings.length - 3 ? 'text-danger' : index === 4 ? 'text-warning' : index === 5 ? 'text-warning' : index === 6 ? 'text-success' : ''} style={{ color: 'white' }}>
                    {index + 1}
                  </th>
                  <td>
                    <span onClick={() => openModal(team.team)} style={{ cursor: 'pointer', color: 'white' }}>
                    {team.team === 'Bayern München' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>
      Bayern 
    </Link>
  ) : team.team === 'Bayer Leverkusen' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Leverkusen</Link>
  ) : team.team === 'SC Freiburg' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Freiburg</Link>
  ) : team.team === 'Eintracht Frankfurt' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Frankfurt</Link>
  ) : team.team === 'Union Berlin' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}> Berlin</Link>
  ) : team.team === 'RB Leipzig' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Leipzig</Link>
  ) : team.team === 'VfB Stuttgart' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Stuttgart</Link>
  ) : team.team === 'Borussia Dortmund' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Dortmund</Link>
  ) : team.team === '1. FC Heidenheim' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Heidenheim</Link>
  ) : team.team === 'FSV Mainz 05' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Mainz</Link>
  ) : team.team === 'Werder Bremen' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>W. Bremen</Link>
  ) : team.team === 'FC Augsburg' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Augsburg</Link>
  ) : team.team === 'VfL Wolfsburg' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Wolfsburg</Link>
  ) : team.team === 'Borussia Mönchengladbach' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Monchenglad</Link>
  ) : team.team === '1899 Hoffenheim' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Hoffenheim</Link>
  ) : team.team === 'VfL Bochum' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Bochum</Link>
  ) : team.team === 'FC St. Pauli' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>St. Pauli</Link>
  ) : team.team === 'Holstein Kiel' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}> Kiel</Link>
  ) : (
    team.team
  )}

                    </span>
                  </td>
                  <td className='text-light'>{team.matchesPlayed}</td>
                  <td className='text-light'>{team.wins}</td>
                  <td className='text-light'>{team.draws}</td>
                  <td className='text-light'>{team.losses}</td>
                  <td className='text-light'>{team.points}</td>
                </tr>
              ))}

            </tbody>
          </table>
        )}
      </div>
      <div className="container mt-3">
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-primary me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">UEFA Champions League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-warning me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-success me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa Conference League Qualification</div>
        </div>
        <div className="d-flex align-items-center" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-danger me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Relegation</div>
        </div>
      </div>
    </div>

      {/* Modal for displaying team details */}
      {isModalOpen && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title ">{selectedTeam}</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              {/* Display Team Kit Image */}
              <div className="modal-body">
  <div className="text-center mb-3">
    {teamKits[selectedTeam] && (
      <img
        src={teamKits[selectedTeam]}
        alt={`${selectedTeam} Kit`}
        style={{ maxWidth: '100px', maxHeight: '100px', marginBottom: '15px' }}
      />
    )}
  </div>
  
  {/* Add cards for Total Wins, Home Wins, and Away Wins */}

  <div className="row text-center mb-3">
  <div className="col-12 col-sm-4 mb-3 mb-sm-0">
      <div className="card border-bg-warning text-light" style={{ color: 'green' }}>
        <div className="card-body">
          <h6 className="card-title"style={{ color: 'green' }}>Total Wins</h6>
          <p className="card-text"style={{ color: 'green' }}>{teamStats ? teamStats.wins : 0}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4 mb-3 mb-sm-0">
      <div className="card border-bg-warning text-light" style={{ color: '#f49f05' }}>
        <div className="card-body">
          <h6 className="card-title"style={{ color: '#f49f05' }}>Total Draws</h6>
          <p className="card-text"style={{ color: '#f49f05' }}>{teamStats ? teamStats.draws : 0}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4 mb-3 mb-sm-0">
      <div className="card border-bg-warning text-light" style={{ color: 'red' }}>
        <div className="card-body">
          <h6 className="card-title"style={{ color: 'red' }}>Total Losses</h6>
          <p className="card-text"style={{ color: 'red' }}>{teamStats ? teamStats.losses : 0}</p>
        </div>
      </div>
    </div>
  </div>

  <div className="row text-center mb-3">
    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
      <div className="card border-bg-warning text-light" style={{ color: 'blue' }}>
        <div className="card-body">
          <h5 className="card-title"style={{ color: 'blue' }}>Home Wins</h5>
          <p className="card-text" style={{ color: 'blue' }}>
            {teamStats ? `${teamStats.homeWins}/${teamStats.homeGamesPlayed}` : '0/0'}
          </p>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
      <div className="card border-bg-warning ">
        <div className="card-body">
          <h5 className="card-title" style={{ color: 'purple' }}>Away Wins</h5>
          <p className="card-text" style={{ color: 'purple' }}>
            {teamStats ? `${teamStats.awayWins}/${teamStats.awayGamesPlayed}` : '0/0'}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div className="mb-3">
  <h6>Last 5 Games</h6>
  <div className="d-flex justify-content-center">
    {formArray.map((result, index) => (
      <span 
        key={index}
        className="badge mx-1"
        style={{ 
          fontSize: '1.2rem', 
          padding: '0.5rem 1rem', 
          color: 'white', // Ensure contrast
          backgroundColor: getColorForMatch(result) // Dynamically set color
        }}
      >
        {result}
      </span>
    ))}
  </div>
</div>
  {/* Existing cards for Draws and Losses */}
</div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary text-light"
                  onClick={() => {
                    closeModal(); // Close the modal
                    if (selectedTeam) {
                      const teamSlug = selectedTeam.toLowerCase().replace(/\s+/g, '');
                      navigate(`/${teamSlug}`);
                    } else {
                      console.error("Selected team is undefined");
                    }
                  }}
                >
                  View full stats
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BundesligaStandingsPicks;
