import Arsenal from '../Team-Kits-Images/Arsenal.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Bournemouth from '../Team-Kits-Images/Bournemouth.png'
import Brentford from '../Team-Kits-Images/Brentford.png'
import Brighton from '../Team-Kits-Images/Brighton.png'
import Burnley from '../Team-Kits-Images/Burnley.png'
import Chelsea from '../Team-Kits-Images/Chelsea.png'
import CrystalPalace from '../Team-Kits-Images/CrystalPalace.png'
import Everton from '../Team-Kits-Images/Everton.png'
import Fulham from '../Team-Kits-Images/Fulham.png'
import Ipswich from '../Team-Kits-Images/Ipswitch.png'
import Leicester from '../Team-Kits-Images/Leicester.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import Luton from '../Team-Kits-Images/LutonTown.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import ManUnited from '../Team-Kits-Images/Man.United.png'
import Newcastle from '../Team-Kits-Images/Newcastle.png'
import NottinghamForest from '../Team-Kits-Images/NottinghamForest.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Southampton from '../Team-Kits-Images/Southampton.png'
import Tottenham from '../Team-Kits-Images/Tottenham.png'
import WestHam from '../Team-Kits-Images/WestHam.png'
import Wolves from '../Team-Kits-Images/Wolves.png'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
 import { Link } from 'react-router-dom'

 function Fixture() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); 
  const [buttonsToShow, setButtonsToShow] = useState(0);

  const [dateRanges] = useState([
    { start: '2024-06-11', end: '2024-08-20' },  // Matchweek 1
    { start: '2024-08-20', end: '2024-08-26' },  // Matchweek 2
    { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 3
    { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 4
    { start: '2024-09-16', end: '2024-09-23' },  // Matchweek 5
    { start: '2024-09-23', end: '2024-09-31' },  // Matchweek 6
    { start: '2024-09-31', end: '2024-10-07' },  // Matchweek 7
    { start: '2024-10-07', end: '2024-10-22' },  // Matchweek 8
    { start: '2024-10-22', end: '2024-10-28' },  // Matchweek 9
    { start: '2024-10-28', end: '2024-11-05' },  // Matchweek 10
    { start: '2024-11-05', end: '2024-11-11' },  // Matchweek 11
    { start: '2024-11-11', end: '2024-11-26' },  // Matchweek 12
    { start: '2024-11-26', end: '2024-12-01' },  // Matchweek 13
    { start: '2024-12-01', end: '2024-12-05' },  // Matchweek 14
    { start: '2024-12-05', end: '2024-12-08' },  // Matchweek 15
    { start: '2024-12-08', end: '2024-12-15' },  // Matchweek 16
    { start: '2024-12-15', end: '2024-12-22' },  // Matchweek 17
    { start: '2024-12-22', end: '2024-12-27' },  // Matchweek 18
    { start: '2024-12-27', end: '2024-12-30' },  // Matchweek 19
    { start: '2025-01-01', end: '2025-01-06' },  // Matchweek 20
    { start: '2025-01-06', end: '2025-01-16' },  // Matchweek 21
    { start: '2025-01-16', end: '2025-01-19' },  // Matchweek 22
    { start: '2025-01-19', end: '2025-01-26' },  // Matchweek 23
    { start: '2025-01-26', end: '2025-02-02' },  // Matchweek 24
    { start: '2025-02-02', end: '2025-02-16' },  // Matchweek 25
    { start: '2025-02-16', end: '2025-02-23' },  // Matchweek 26
    { start: '2025-02-23', end: '2025-02-27' },  // Matchweek 27
    { start: '2025-02-27', end: '2025-03-09' },  // Matchweek 28
    { start: '2025-03-09', end: '2025-03-16' },  // Matchweek 29
    { start: '2025-03-16', end: '2025-04-03' },  // Matchweek 30
    { start: '2025-04-03', end: '2025-04-06' },  // Matchweek 31
    { start: '2025-04-06', end: '2025-04-13' },  // Matchweek 32
    { start: '2025-04-13', end: '2025-04-20' },  // Matchweek 33
    { start: '2025-04-20', end: '2025-04-27' },  // Matchweek 34
    { start: '2025-04-27', end: '2025-05-04' },  // Matchweek 35
    { start: '2025-05-04', end: '2025-05-11' },  // Matchweek 36
    { start: '2025-05-11', end: '2025-05-19' },  // Matchweek 37
    { start: '2025-05-19', end: '2025-07-20' },  // Matchweek 38
]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);

  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);


  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamLogos = {
    'Arsenal': Arsenal,
    'Aston Villa': AstonVilla,
    'Bournemouth': Bournemouth,
    'Brentford': Brentford,
    'Brighton': Brighton,
    'Burnley': Burnley,
    'Chelsea': Chelsea,
    'Crystal Palace': CrystalPalace,
    'Everton': Everton,
    'Fulham': Fulham,
    'Liverpool': Liverpool,
    'Luton': Luton,
    'Manchester City': ManCity,
    'Manchester United': ManUnited,
    'Newcastle': Newcastle,
    'Nottingham Forest': NottinghamForest,
    'Sheffield Utd': SheffieldUnited,
    'Tottenham': Tottenham,
    'West Ham': WestHam,
    'Wolves': Wolves,
    'Ipswich': Ipswich,
    'Leicester': Leicester,
    'Southampton': Southampton
  };
  
  const teamInitials = {
    'Arsenal': 'ARS',
    'Aston Villa': 'AVL',
    'Bournemouth': 'BOU',
    'Brentford': 'BRE',
    'Brighton': 'BHA',
    'Burnley': 'BUR',
    'Chelsea': 'CHE',
    'Crystal Palace': 'CRY',
    'Everton': 'EVE',
    'Fulham': 'FUL',
    'Liverpool': 'LIV',
    'Luton': 'LUT',
    'Manchester City': 'MCI',
    'Manchester United': 'MUN',
    'Newcastle': 'NEW',
    'Nottingham Forest': 'NFO',
    'Sheffield Utd': 'SHU',
    'Tottenham': 'TOT',
    'West Ham': 'WHU',
    'Wolves': 'WOL',
    'Ipswich': 'IPS',
    'Leicester': 'LEI',
    'Southampton': 'SOU'
  };

  const stadiums = {
    "Arsenal": "Stadium: Emirates Stadium",
    "Aston Villa": "Stadium: Villa Park",
    "Bournemouth": "Stadium: Vitality Stadium",
    "Brentford": "Stadium: Gtech Community Stadium",
    "Brighton": "Stadium: Amex Stadium",
    "Burnley": "Stadium: Turf Moor",
    "Chelsea": "Stadium: Stamford Bridge",
    "Crystal Palace": "Stadium: Selhurst Park",
    "Everton": "Stadium: Goodison Park",
    "Fulham": "Stadium: Craven Cottage",
    "Liverpool": "Stadium: Anfield",
    "Luton": "Stadium: Kenilworth Road",
    "Manchester City": "Stadium: Etihad Stadium",
    "Manchester United": "Stadium: Old Trafford",
    "Newcastle": "Stadium: St. James' Park",
    "Nottingham Forest": "Stadium: City Ground",
    "Sheffield United": "Stadium: Bramall Lane",
    "Tottenham": "Stadium: Tottenham Hotspur Stadium",
    "West Ham": "Stadium: London Stadium",
    "Wolves": "Stadium: Molineux Stadium",
    "Ipswich": "Stadium: Portman Road",
    "Leicester": "Stadium: King Power Stadium",
    "Southampton": "Stadium: St. Mary's Stadium",
  };
  
  const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };
  
  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(Round);
    handlePageChange(index + 1);
  };

  // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  
  useEffect(() => {
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonsToShow = Math.floor(containerWidth / 125); // Adjust this value based on your design
        setButtonsToShow(Math.min(8, calculatedButtonsToShow));
        calculateStartIndex(currentPage, Math.min(8, calculatedButtonsToShow));
      }
    };

    calculateButtonsToShow();

    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, [currentPage]);

  const calculateStartIndex = (currentPage, buttonsToShow) => {
    let minIndex = Math.max(0, currentPage - Math.floor(buttonsToShow / 2));
    minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
    setStartIndex(minIndex);
  };

  const handleMatchdaySelectSmooth = (index) => {
    setSelectedMatchdayIndex(index);
    handleMatchdaySelect(index);
  };

  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -((100 / buttonsToShow) + 10), // Move left by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + buttonsToShow < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: (100 / buttonsToShow) + 10, // Move right by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex + 1);
    }
  };
  
  return (
    <div>
    <div className='App-headerV8 '>
    <Navbar />
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif' }}>EPL Fixtures</h2>
      <div className="text-light text-center" style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
  {/* Mobile Dropdown */}
  <div className="dropdown d-inline-block d-md-none">
    <button className="btn btn-purple dropdown-toggle" type="button" id="groupStageDropdown" data-bs-toggle="dropdown" aria-expanded="true">
      {selectedMatchday}
    </button>
    <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
      <ul className="list-group">
        {dateRanges.map((range, index) => (
          <li key={index} className="list-group-item">
            <button
              className={`dropdown-item ${selectedMatchday === `Round ${index + 1}` ? 'btn-purple text-light' : ''}`}
              onClick={() => handleMatchdaySelect(index)}
              style={{ width: '100%' }}
            >
              Round {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>

  {/* Horizontal Scroll for Large Screens */}
  <div className="container">
    <div className="d-none d-md-flex align-items-center justify-content-center" style={{ width: '100%', marginLeft: '0rem' }} ref={containerRef}>
      <button className="btn btn-outline-light" onClick={handleLeftArrowClick} disabled={startIndex === 0} style={{ width: '5%', marginRight: '1rem' }}>
        &lt;
      </button>

      <div
        className="rounds-container"
        ref={roundsContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '70%', // Set to allow space for buttons
          marginLeft: '1rem',
          scrollBehavior: 'smooth',
        }}
      >
        {dateRanges.slice(startIndex, startIndex + buttonsToShow).map((range, index) => (
          <button
            key={startIndex + index}
            className={`btn me-2 ${selectedMatchday === `Round ${startIndex + index + 1}` ? 'btn-purple' : 'btn-outline-light'}`}
            style={{ width: `calc(${(100 / buttonsToShow)}% - 9px)` }}
            onClick={() => handleMatchdaySelect(startIndex + index)}
          >
            Round {startIndex + index + 1}
          </button>
        ))}
      </div>

      <button className="btn btn-outline-light" onClick={handleRightArrowClick} disabled={startIndex + buttonsToShow >= dateRanges.length} style={{ width: '5%', marginLeft: '1rem' }}>
        &gt;
      </button>
    </div>
  </div>
</div>

      <div className=' '>
        <div className='row text-center justify-content-center mx-auto bg-transparent text-light'>        
          <div className=' col-lg-6  text-center justify-content-center bg-transparent text-light'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
              let points = 0;
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-1 bg-transparent text-light border-light'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
      <h6 className='card-title mb-3'>{stadiums[fixture.teams.home.name]}</h6>
    )}
              
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                                  {/* Input */}
                    <>
<div className="col padtop1 ">
<input
  className="form-control text-center fs-3 text-light"
  style={{ backgroundColor: 'transparent', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
  disabled
/>
</div>
<div className="col padtop1">
<input
  className="form-control text-center fs-3 text-light"
  style={{ backgroundColor: 'transparent', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
  disabled
/>
</div>
</>
              {/* Input */}
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
              
                </div>
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
 style={{ 
   backgroundColor: '#9370DB', 
   borderRadius: '5px', 
   borderBottomLeftRadius: '0px',  // Set bottom-left corner radius to 0
   borderBottomRightRadius: '0px'  // Set bottom-right corner radius to 0
 }}
>
<p className="text-center mb-2 btn-sm text-bold" 
 style={{ 
   color: '#fff', 
   paddingTop: '4px',  
   borderTopLeftRadius: '1px' 
 }}
>
Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}
</p>
</div>
                {fixture.fixture.status.short === 'NS' && (
<Link
    to="/premierleague"
    className="btn btn-success"
    style={{
        position: 'absolute',
        bottom: '0',           // Flush with the bottom edge
        right: '0',            // Flush with the right edge
        padding: '8px 12px',  // Adjust padding for button size
        backgroundColor: 'green',
        color: 'white',
        textDecoration: 'none',
        fontSize: '0.9rem',    // Adjust the font size as needed
        fontWeight: 'bold',
        borderRadius: '0',     // Ensure no border-radius on other corners
        borderTopLeftRadius: '10px',  // 10px border radius only on top-left corner
        borderBottomRightRadius: '5px',  // 10px border radius only on top-left corner
        margin: '0',           // Ensure no extra margins
        border: '3px',        // No borders
        width: 'auto',         // Adjust width as needed
        height: 'auto'         // Adjust height as needed
    }}
>
     Predict
</Link>
)}
              </div>
              );
            })}
          </div>         
        </div>
      </div>

    <Footer />
    </div>
  </div>
  );
}

export default Fixture;
