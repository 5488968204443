import 	Blackburn from '../Team-Kits-Images/Blackburn.png'
import Derby from '../Team-Kits-Images/Derby.png'
import 	Preston from '../Team-Kits-Images/Preston.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Cardiff from '../Team-Kits-Images/Cardiff.png'
import Sunderland from '../Team-Kits-Images/Sunderland.png'
import 	Hull from '../Team-Kits-Images/Hull.png'
import 	Bristol from '../Team-Kits-Images/Bristol.png'
import 	Portsmouth from '../Team-Kits-Images/Portsmouth.png'
import Middlesbrough	 from '../Team-Kits-Images/Middlesbrough.png'
import Swansea	 from '../Team-Kits-Images/Swansea.png'
import Millwall	 from '../Team-Kits-Images/Millwall.png'
import Watford		 from '../Team-Kits-Images/Watford.png'
import Oxford	 from '../Team-Kits-Images/Oxford.png'
import Norwich	 from '../Team-Kits-Images/Norwich.png'
import QPR	 from '../Team-Kits-Images/QPR.png'
import WestBrom	 from '../Team-Kits-Images/WestBrom.png'
import Stoke	 from '../Team-Kits-Images/Stoke.png'
import Coventry	 from '../Team-Kits-Images/Coventry.png'
import Wednesday	 from '../Team-Kits-Images/Wednesday.png'
import Plymouth	 from '../Team-Kits-Images/Plymouth.png'
import Luton	 from '../Team-Kits-Images/LutonTown.png'
import Burnley	 from '../Team-Kits-Images/Burnley.png'
import Leeds	 from '../Team-Kits-Images/Leeds.png'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
 import { Link } from 'react-router-dom'

 function ChampionshipFixtures() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); 
  const [buttonsToShow, setButtonsToShow] = useState(0);

  const [dateRanges] = useState([
    { start: '2024-07-03', end: '2024-08-13' },  // Matchweek 1
    { start: '2024-08-13', end: '2024-08-19' },  // Matchweek 2
    { start: '2024-08-19', end: '2024-08-26' },  // Matchweek 3
    { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 4
    { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 5
    { start: '2024-09-16', end: '2024-09-23' },  // Matchweek 6
    { start: '2024-09-23', end: '2024-09-30' },  // Matchweek 7
    { start: '2024-09-30', end: '2024-10-03' },  // Matchweek 8
    { start: '2024-10-03', end: '2024-10-07' },  // Matchweek 9
    { start: '2024-10-07', end: '2024-10-21' },  // Matchweek 10
    { start: '2024-10-21', end: '2024-10-24' },  // Matchweek 11
    { start: '2024-10-24', end: '2024-10-28' },  // Matchweek 12
    { start: '2024-10-28', end: '2024-11-04' },  // Matchweek 13
    { start: '2024-11-04', end: '2024-11-08' },  // Matchweek 14
    { start: '2024-11-08', end: '2024-11-11' },  // Matchweek 15
    { start: '2024-11-11', end: '2024-11-25' },  // Matchweek 16
    { start: '2024-11-25', end: '2024-11-28' },  // Matchweek 17
    { start: '2024-11-28', end: '2024-12-02' },  // Matchweek 18
    { start: '2024-12-02', end: '2024-12-09' },  // Matchweek 19
    { start: '2024-12-09', end: '2024-12-12' },  // Matchweek 20
    { start: '2024-12-12', end: '2024-12-16' },  // Matchweek 21
    { start: '2024-12-16', end: '2024-12-23' },  // Matchweek 22
    { start: '2024-12-23', end: '2024-12-27' },  // Matchweek 23
    { start: '2024-12-27', end: '2024-12-30' },  // Matchweek 24
    { start: '2024-12-30', end: '2025-01-02' },  // Matchweek 25 2025 
    { start: '2025-01-01', end: '2025-01-07' },  // Matchweek 26
    { start: '2025-01-07', end: '2025-01-19' },  // Matchweek 27
    { start: '2025-01-19', end: '2025-01-23' },  // Matchweek 28
    { start: '2025-01-23', end: '2025-01-26' },  // Matchweek 29
    { start: '2025-01-26', end: '2025-02-02' },  // Matchweek 30
    { start: '2025-02-02', end: '2025-02-09' },  // Matchweek 31
    { start: '2025-02-09', end: '2025-02-13' },  // Matchweek 32
    { start: '2025-02-13', end: '2025-02-16' },  // Matchweek 33
    { start: '2025-02-16', end: '2025-02-23' },  // Matchweek 34
    { start: '2025-02-23', end: '2025-03-02' },  // Matchweek 35
    { start: '2025-03-02', end: '2025-03-09' },  // Matchweek 36
    { start: '2025-03-09', end: '2025-03-13' },  // Matchweek 37
    { start: '2025-03-13', end: '2025-03-16' },  // Matchweek 38
    { start: '2025-03-16', end: '2025-03-30' },  // Matchweek 39
    { start: '2025-03-30', end: '2025-04-06' },  // Matchweek 40
    { start: '2025-04-06', end: '2025-04-10' },  // Matchweek 41
    { start: '2025-04-10', end: '2025-04-13' },  // Matchweek 42
    { start: '2025-04-13', end: '2025-04-19' },  // Matchweek 43
    { start: '2025-04-19', end: '2025-04-22' },  // Matchweek 44
    { start: '2025-04-22', end: '2025-04-27' },  // Matchweek 45
    { start: '2025-04-27', end: '2025-05-23' }   // Matchweek 46
]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);

  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(Round);
    handlePageChange(index + 1);
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamInitials = {
    'Blackburn': 'BLB',
    'Bristol City': 'BRC',
    'Cardiff': 'CAR',
    'Coventry': 'COV',
    'Hull City': 'HUL',
    'Middlesbrough': 'MID',
    'Millwall': 'MIL',
    'Norwich': 'NOR',
    'Plymouth': 'PLY',
    'Portsmouth': 'POR',
    'Preston': 'PRE',
    'QPR': 'QPR',
    'Sheffield Wednesday': 'SHW',
    'Stoke': 'STO',
    'Sunderland': 'SUN',
    'Swansea': 'SWA',
    'Watford': 'WAT',
    'West Brom': 'WBA',
    'Leeds': 'LEE',
    'Derby': 'DER',
    'Sheffield Utd': 'SHU',
    'Oxford United': 'OXF',
    'Luton': 'LUT',
    'Burnley': 'BUR'
};

  
  

  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const teamLogos = {
    'Blackburn': Blackburn,
    'Bristol City': Bristol,
    'Cardiff': Cardiff,
    'Coventry': Coventry,
    'Hull City': Hull,
    'Middlesbrough': Middlesbrough,
    'Millwall': Millwall,
    'Norwich': Norwich,
    'Plymouth': Plymouth,
    'Portsmouth': Portsmouth,
    'Preston': Preston,
    'QPR': QPR,
    'Sheffield Wednesday': Wednesday,
    'Stoke': Stoke,
    'Sunderland': Sunderland,
    'Swansea': Swansea,
    'Watford': Watford,
    'West Brom': WestBrom,
    'Leeds': Leeds,
    'Derby': Derby,
    'Sheffield Utd': SheffieldUnited,
    'Oxford United': Oxford,
    'Stoke City': Stoke,
    'Luton': Luton,
    'Burnley': Burnley,




  };
  const renderTeamLogo = (teamName) => {
  const logo = teamLogos[teamName];
  if (logo) {
    return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
  }
  return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };

  const stadiums = {
    'Blackburn': 'Stadium: Ewood Park',
    'Bristol City': 'Stadium: Ashton Gate',
    'Cardiff': 'Stadium: Cardiff City Stadium',
    'Coventry': 'Stadium: Coventry Building Society Arena',
    'Hull City': 'Stadium: MKM Stadium',
    'Middlesbrough': 'Stadium: Riverside Stadium',
    'Millwall': 'Stadium: The Den',
    'Norwich': 'Stadium: Carrow Road',
    'Plymouth': 'Stadium: Home Park',
    'Portsmouth': 'Stadium: Fratton Park',
    'Preston': 'Stadium: Deepdale',
    'QPR': 'Stadium: Loftus Road',
    'Sheffield Wednesday': 'Stadium: Hillsborough',
    'Stoke City': 'Stadium: Bet365 Stadium',
    'Sunderland': 'Stadium: Stadium of Light',
    'Swansea': 'Stadium: Swansea.com Stadium',
    'Watford': 'Stadium: Vicarage Road',
    'West Brom': 'Stadium: The Hawthorns',
    'Leeds': 'Stadium: Elland Road',
    'Derby': 'Stadium: Pride Park',
    'Sheffield Utd': 'Stadium: Bramall Lane',
    'Oxford United': 'Stadium: Kassam Stadium',
    'Luton': 'Stadium: Kenilworth Road',
    'Burnley': 'Stadium: Turf Moor',
  };
  
  


  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

  // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  
  useEffect(() => {
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonWidth = 100; // Adjust width as needed
        const calculatedButtonsToShow = Math.floor(containerWidth / (calculatedButtonWidth + 8));
        setButtonWidth(calculatedButtonWidth);
        setButtonsToShow(calculatedButtonsToShow);
        
        // Dynamically set startIndex around the current round
        calculateStartIndex(currentPage, calculatedButtonsToShow); 
      }
    };
  
    calculateButtonsToShow();
  
    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, [currentPage, containerRef.current]);
  
  // Adjust calculateStartIndex to center the rounds based on current page
  const calculateStartIndex = (currentPage, buttonsToShow) => {
    let minIndex;
  
    // Center start index around current round within available buttonsToShow
    if (currentPage - Math.floor(buttonsToShow / 2) >= 0) {
      minIndex = currentPage - Math.floor(buttonsToShow / 2);
    } else {
      minIndex = 0; // fallback for early rounds
    }
    
    // Ensure we don’t exceed the available rounds
    minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
  
    setStartIndex(minIndex);
  };
  


  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -buttonWidth, // Scroll by one button width
        behavior: 'smooth', // Make the scroll smooth
      });
      setStartIndex(startIndex - 1); // Decrement startIndex by 1
    }
  };
  
  // Function to handle right arrow click (move by 1 round)
  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + 1 < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: buttonWidth, // Scroll by one button width
        behavior: 'smooth', // Make the scroll smooth
      });
      setStartIndex(startIndex + 1); // Increment startIndex by 
    }
  };
  
  return (
    <div>
    <div className='App-headerV'>
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif' }}>Championship Fixtures</h2>
      <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
        <div className="dropdown d-inline-block">
          <button
            className="btn btn-purple dropdown-toggle"
            type="button"
            id="groupStageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedMatchday}
          </button>
          <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
            <ul className="list-group">
              {dateRanges.map((range, index) => (
                <li key={index} className="list-group-item">
                  <button className="dropdown-item" onClick={() => handleMatchdaySelect(index)}>
                    Round {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
   
      <div className='container'>
        <div className='row'>        
          {/* Main content area */}
          <div className=' col-12'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
              let points = 0;
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-0 bg-transparent text-light'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
      <h6 className='card-title mb-3'>{stadiums[fixture.teams.home.name]}</h6>
    )}
              
                  <div className="row align-items-center">
                    <div className="col-2 text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                                  {/* Input */}
                    <>
<div className="col-4 padtop1 ">
<input
  className="form-control text-center fs-3 text-light bg-transparent"
  style={{ backgroundColor: '#fff', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
  disabled
/>
</div>
<div className="col-4 padtop1">
<input
  className="form-control text-center fs-3 text-light bg-transparent"
  style={{ backgroundColor: '#fff', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
  disabled
/>
</div>
</>
              {/* Input */}
                    <div className="col-2 text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
              
                </div>
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
 style={{ 
   backgroundColor: '#9370DB', 
   borderRadius: '5px', 
   borderBottomLeftRadius: '5px',  // Set bottom-left corner radius to 0
   borderBottomRightRadius: '5px'  // Set bottom-right corner radius to 0
 }}
>
<p className="text-center mb-2 btn-sm text-bold" 
 style={{ 
   color: '#fff', 
   paddingTop: '4px',  
   borderTopLeftRadius: '1px' 
 }}
>
Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}
</p>
</div>
<hr className="my-3" style={{ height: '4px', backgroundColor: '#9370DB', border: 'none' }} />

              </div>
              );
            })}
          </div>         
        </div>
      </div>
    </div>
  </div>
  );
}

export default ChampionshipFixtures;
