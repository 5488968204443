import Sundowns from '../Team-Kits-Images/Sundowns.png'
import Pirates from '../Team-Kits-Images/Pirates.png'
import Arrows from '../Team-Kits-Images/Arrows.png'
import Polokwane from '../Team-Kits-Images/Polokwane.png'
import Richards from '../Team-Kits-Images/Richards.png'
import Kaizer from '../Team-Kits-Images/Kaizer.png'
import Sekh from '../Team-Kits-Images/Sekh.png'
import Chippa from '../Team-Kits-Images/Chippa.png'
import Royal from '../Team-Kits-Images/Royal.png'
import Cape from '../Team-Kits-Images/Cape.png'
import Supersport from '../Team-Kits-Images/Supersport.png'
import Amazulu from '../Team-Kits-Images/Amazulu.png'
import Galaxy from '../Team-Kits-Images/Galaxy.png'
import Stell from '../Team-Kits-Images/Stell.png'
import Magesi from '../Team-Kits-Images/Magesi.png'
import Marumo from '../Team-Kits-Images/Margumo.png'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
 import { Link } from 'react-router-dom'

 function SASoccerFixtures() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); 
  const [buttonsToShow, setButtonsToShow] = useState(0);

  const [dateRanges] = useState([
    { start: '2024-09-01', end: '2024-09-30' },  // Matchweek 1 (September 2024)
    { start: '2024-10-01', end: '2024-10-31' },  // Matchweek 2 (October 2024)
    { start: '2024-11-01', end: '2024-11-30' },  // Matchweek 3 (November 2024)
    { start: '2024-12-01', end: '2024-12-31' },  // Matchweek 4 (December 2024)
    { start: '2025-01-01', end: '2025-01-31' },  // Matchweek 5 (January 2025)
    { start: '2025-02-01', end: '2025-02-28' },  // Matchweek 6 (February 2025)
    { start: '2025-03-01', end: '2025-03-31' },  // Matchweek 7 (March 2025)
    { start: '2025-04-01', end: '2025-04-30' },  // Matchweek 8 (April 2025)
    { start: '2025-05-01', end: '2025-05-31' },  // Matchweek 9 (May 2025)
        // Add more matchweeks as needed
]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);

  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/288/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(Round);
    handlePageChange(index + 1);
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamLogos = {
    'Mamelodi Sundowns': Sundowns,
    'Orlando Pirates': Pirates,
    'Golden Arrows': Arrows,
    'Polokwane City': Polokwane,
    'Richards Bay': Richards,
    'Kaizer Chiefs': Kaizer,
    'Sekhukhune United': Sekh,
    'Chippa United': Chippa,
    'Royal AM': Royal,
    'Cape Town City': Cape,
    'Supersport United': Supersport,
    'Amazulu': Amazulu,
    'TS Galaxy': Galaxy,
    'Stellenbosch': Stell,
    'Magesi': Magesi,
    'Marumo Gallants': Marumo
};
  
const teamInitials = {
    'Mamelodi Sundowns': 'MSFC',
    'Orlando Pirates': 'OPFC',
    'Golden Arrows': 'GAFC',
    'Polokwane City': 'PFC',
    'Richards Bay': 'RBFC',
    'Kaizer Chiefs': 'KCFC',
    'Sekhukhune United': 'SUFC',
    'Chippa United': 'CUFC',
    'Royal AM': 'RAM',
    'Cape Town City': 'CTC',
    'Supersport United': 'SSU',
    'Amazulu': 'AFC',
    'TS Galaxy': 'TSG',
    'Stellenbosch': 'SFC',
    'Magesi': 'MFC',
    'Marumo Gallants': 'MGFC'
};

  
const renderTeamLogo = (teamName) => {
  const logo = teamLogos[teamName];
  if (logo) {
    return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
  }
  return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
};

const renderTeamInitials = (teamName) => {
  return teamInitials[teamName] || teamName;
};
const stadiums = {
  'Mamelodi Sundowns': 'Stadium: Loftus Versfeld Stadium',
  'Orlando Pirates': 'Stadium: Orlando Stadium',
  'Golden Arrows': 'Stadium: Princess Magogo Stadium',
  'Polokwane City': 'Stadium: Peter Mokaba Stadium',
  'Richards Bay': 'Stadium: Richards Bay Stadium',
  'Kaizer Chiefs': 'Stadium: FNB Stadium',
  'Sekhukhune United': 'Stadium: Peter Mokaba Stadium',
  'Chippa United': 'Stadium: Nelson Mandela Bay Stadium',
  'Royal AM': 'Stadium: Chatsworth Stadium',
  'Cape Town City': 'Stadium: Cape Town Stadium',
  'Supersport United': 'Stadium: Lucas Masterpieces Moripe Stadium',
  'Amazulu': 'Stadium: Moses Mabhida Stadium',
  'TS Galaxy': 'Stadium: Mbombela Stadium',
  'Stellenbosch': 'Stadium: Danie Craven Stadium',
  'Magesi': 'Stadium: Old Peter Mokaba Stadium',
  'Marumo Gallants': 'Stadium: Peter Mokaba Stadium',
};



  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

  // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  
  useEffect(() => {
    // Calculate button width and how many can fit
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonWidth = 100; // Example width, adjust as necessary
        const calculatedButtonsToShow = Math.floor(containerWidth / (calculatedButtonWidth + 8)); // 10 is for margin
        setButtonWidth(calculatedButtonWidth);
        setButtonsToShow(calculatedButtonsToShow);
      }
    };
    // Set initial calculation
    calculateButtonsToShow();

    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, []);

  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -buttonWidth, // Scroll by one button width
        behavior: 'smooth', // Make the scroll smooth
      });
      setStartIndex(startIndex - 1); // Decrement startIndex by 1
    }
  };
  
  // Function to handle right arrow click (move by 1 round)
  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + 1 < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: buttonWidth, // Scroll by one button width
        behavior: 'smooth', // Make the scroll smooth
      });
      setStartIndex(startIndex + 1); // Increment startIndex by 
    }
  };
  
  return (
<div>
    <div className='App-headerV'>
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif' }}>Premier Soccer League Fixtures</h2>
      <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
        <div className="dropdown d-inline-block">
          <button
            className="btn btn-purple dropdown-toggle"
            type="button"
            id="groupStageDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {selectedMatchday}
          </button>
          <div className="dropdown-menu matchweek-dropdown" aria-labelledby="groupStageDropdown">
            <ul className="list-group">
              {dateRanges.map((range, index) => (
                <li key={index} className="list-group-item">
                  <button className="dropdown-item" onClick={() => handleMatchdaySelect(index)}>
                    Round {index + 1}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
   
      <div className='container'>
        <div className='row'>        
          {/* Main content area */}
          <div className=' col-12'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
              let points = 0;
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-0 bg-transparent text-light'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
      <h6 className='card-title mb-3'>{stadiums[fixture.teams.home.name]}</h6>
    )}
              
                  <div className="row align-items-center">
                    <div className="col-2 text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                                  {/* Input */}
                    <>
<div className="col-4 padtop1 ">
<input
  className="form-control text-center fs-3 text-light bg-transparent"
  style={{ backgroundColor: '#fff', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
  disabled
/>
</div>
<div className="col-4 padtop1">
<input
  className="form-control text-center fs-3 text-light bg-transparent"
  style={{ backgroundColor: '#fff', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
  disabled
/>
</div>
</>
              {/* Input */}
                    <div className="col-2 text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
              
                </div>
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
 style={{ 
   backgroundColor: '#9370DB', 
   borderRadius: '5px', 
   borderBottomLeftRadius: '5px',  // Set bottom-left corner radius to 0
   borderBottomRightRadius: '5px'  // Set bottom-right corner radius to 0
 }}
>
<p className="text-center mb-2 btn-sm text-bold" 
 style={{ 
   color: '#fff', 
   paddingTop: '4px',  
   borderTopLeftRadius: '1px' 
 }}
>
Kick Off: {convertToFormattedDateTime(fixture.fixture.date)}
</p>
</div>
<hr className="my-3" style={{ height: '4px', backgroundColor: '#9370DB', border: 'none' }} />

              </div>
              );
            })}
          </div>         
        </div>
      </div>
    </div>
  </div>
  );
}

export default SASoccerFixtures;
