import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Terms() {

  const { t } = useTranslation();

  return (
    <div>
<div className='App-headerV9'>

<Navbar />
</div>      <div className='mb-6'></div>
<div className='container '>

<b  className='pad'>Terms and Conditions for Predictify </b>
<ul />
<b className='mb-3 padtop ' style={{marginTop: '10px'}}>Introduction </b>
<ul />

<p>Welcome to Predictify's Sports Predictor Game (the "Game"). By participating in the Game, you agree to these Terms and Conditions. Please read them carefully before playing.</p>

<b>Eligibility</b>
<ul />

<p>1. Participants must be at least 18 years old.</p>
<p>2. The Game is open to residents of countries where such games are not prohibited by law.</p>

<b>Participation</b>
<ul />

<p>1. To participate, you must create an account and provide accurate information.</p>
<p>2. Each participant can only have one account. Multiple accounts will be disqualified.</p>
<p>3. Predictions must be submitted before the start of the sports event.</p>

<b>Game Rules</b>
<ul />

<p>1. Points are awarded based on the accuracy of your predictions.</p>
<p>2. The scoring system and rules for each event are detailed on the Game’s website.</p>
<p>3. In case of disputes regarding predictions or scores, Predictify’s decision is final.</p>
<b>Privacy</b>
<ul />

<p>1. Your personal information will be used in accordance with our Privacy Policy.</p>
<p>2. By participating in the Game, you consent to the collection and use of your information as outlined in the Privacy Policy.</p>

<b>Conduct</b>

<ul />
<p>1. Participants must not engage in any fraudulent or unethical behavior.</p>
<p>2. Any attempt to manipulate the Game, including but not limited to hacking or creating multiple accounts, will result in disqualification.</p>

<b>Liability</b><b />
<ul />

<p>1. Predictify is not responsible for any technical issues or interruptions that may affect the Game. </p>
<p>2. Predictify is not liable for any loss or damage arising from participation in the Game.</p>

<b>Changes to Terms</b>
<ul />

<p>1. Predictify reserves the right to modify these Terms and Conditions at any time.</p>
<p>2. Changes will be communicated via the Game’s website or email.</p>
<b className='mb-3'>Contact</b>
<ul />

<p>For any questions or concerns, please contact us at support@predictify.app</p>

<p>By participating in the Game, you acknowledge that you have read, understood, and agree to these Terms and Conditions.</p>

<p className='mb-3' style={{marginBottom: '5px'}}>Last updated: 12/06/2024</p>
<ul />
<br />
</div>
<div className='App-headerV9'>

    <Footer />
</div>    </div>

  )
}

export default Terms