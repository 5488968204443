import React, { useState, } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase'; // Import your Firebase configuration
import { Navigate } from 'react-router-dom';
import Image123 from '../LogoWhite4.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


function PassReset() {
  const [email, setEmail] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleReset = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setResetSent(true);
      alert('Please check your email inbox');

    } catch (err) {
      setError(err.code);
      alert(err.code);
    }
    setEmail('')

  };

  return (
    <div>
      <div className='App-headerV8'>
      <Navbar />
        <section className=" py-3 py-md-5 py-xl-8" style={{ height: '100vh' }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                <div className="mb-5">
                  <div className="text-center mb-4">
                    <a href="#!">
                      <img src={Image123} alt=" Logo" width="175" height="57"/>
                    </a>
                  </div>
                  <h4 className="text-center mb-4 text-light"style={{ fontFamily: 'Lato, sans-serif' }}>{t('password_reset1')}</h4>
</div>
                <div className="card border border-light-subtle rounded-4">

                <div className="card-body p-3 p-md-4 p-xl-5">
  <form onSubmit={handleReset}>
    <p className="text-center mb-4">{t('password_reset_description')}</p>
    <div className="row gy-3 overflow-hidden">
      <div className="col-12">
        <input 
          type="email" 
          className="form-control" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          id="passreset" 
          placeholder="name@example.com" 
          required 
        />
      </div>
      <div className="col-12">
        <div className="d-grid">
          <button className="btn btn-purple btn-lg" type="submit">Reset Password</button>
        </div>
      </div>
    </div>
  </form>                
</div>

                </div>
                <div className="row justify-content-center mt-4">
                  <div className="col-auto">
                    <Link to="/login" className="link-secondary text-decoration-none text-light">Login</Link>
                  </div>
                  <div className="col-auto">
                    <Link to="/signup" className="link-secondary text-decoration-none text-light">Sign Up</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
      </div>
    </div>
  );
}

export default PassReset;
