import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { useState, useEffect, useRef, useMemo } from 'react';
import { imageDb, useAuth, db } from '../firebase';
import {  updateProfile } from 'firebase/auth';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getDatabase, get, set, onValue, update, ref as dbRef, ref } from 'firebase/database';
import { getStorage, uploadBytes, getDownloadURL, ref as storageRef } from 'firebase/storage'; // Correct imports for Firebase Storage
import '../App.css'
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa'; // Importing the edit icon
import PassResetProfile from './PassResetProfile';

function UserProfile({ navigate }) {

    const { auth } = useAuth(); // Destructure auth from the useAuth hook
    // this is used so firebase can get the user uid and see if it matches so that it can then allow the user to see their details respectfully
    const [user, setUser] = useState(null);
    const [ memberSince, setMemberSince] = useState('')
    const [image, setImage] = useState('')
    const storage = getStorage(auth.app);
    const [img, setImg] = useState('')
    const [authUser, setAuthUser] = useState(null);
    const [isEditing, setIsEditing] = useState(false); // State to manage edit mode
    const [successMessage, setSuccessMessage] = useState(''); // State for success message
    const database = getDatabase()
    const [ setTotalPoints] = useState(0);
    const [userId, setUserId] = useState(null); // Initially, userId is null
    const { t } = useTranslation();
    const intervalRefs = useRef({});
    const [country, setCountry] = useState('');
    const [role, setRole] = useState('');

    const [firstName, setFirstName] = useState('');
    const [joinedDate, setJoinedDate] = useState(''); // State to store the formatted join date
    const [userData, setUserData] = useState(null); // State to hold user data
    const cutoffDate = new Date('2024-10-02T00:00:00Z');

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
              console.log("User logged in:", user);
              setUser(user);
              setUserId(user.uid); // Set user ID
              setCountry(extractCountryFromDisplayName(user.displayName));
              
          } else {
              console.log("No user found, resetting states.");
              setUser(null);
              setUserId(null); // Reset userId if user is null
              setCountry(''); // Reset country if no user
              setRole('')
          }
      });
  
      return () => unsubscribe();
  }, [auth]);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
            setUser(user);
            const userRef = dbRef(getDatabase(), 'users/' + user.uid);
            const snapshot = await get(userRef);
            if (snapshot.exists()) {
                console.log('User data retrieved:', snapshot.val()); // Debugging line
                setUserData(snapshot.val());
            } else {
                console.log('No user data available');
            }
        } else {
            console.log("No user is logged in.");
        }
    });

    return () => unsubscribe();
}, [auth]);

useEffect(() => {
    if (user && userData) {
        const creationTime = user.metadata.creationTime;
        const createdAt = userData.createdAt; // Change to your actual field name
        console.log('Creation time:', creationTime); // Debugging line
        console.log('CreatedAt field:', createdAt); // Debugging line

        // Check if createdAt is present and is a valid date
        if (createdAt) {
            const memberSinceDate = new Date(createdAt);
            if (new Date(creationTime) < cutoffDate) {
                setMemberSince(format(new Date(creationTime), 'dd/MM/yyyy'));
            } else {
                setMemberSince(format(memberSinceDate, 'dd/MM/yyyy'));
            }
        } else {
            console.warn('createdAt field is missing from userData:', userData); // Warn if missing
            // Optionally handle the case where createdAt is not found
        }
    }
}, [user, userData]);

const format = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

      const extractNameOnlyFromDisplayName = (displayName) => {
        if (!displayName) {
          return ''; // Handle the case where displayName is null or undefined
        }
      
        // Display only the User's name. The only data that is not in ()
        const match = displayName.match(/^[^(]+/);
      
        // Check if there is a match and return the content outside parentheses
        return match ? match[0].trim() : displayName;
      };

      const extractSurnameFromDisplayName = (displayName) => {
        if (!displayName) {
          return '';
        }
      
        // Using regular expression to extract content inside the second set of parentheses (surname)
        const match = displayName.match(/\([^)]+\)\s\(([^)]+)\)/);
      
        return match ? match[1] : '';
      };

      // a function to extract only the country and display it
      const extractCountryFromDisplayName = (displayName) => {
        if (!displayName) {
          return '';
        }
      
        // Using regular expression to extract content inside the second set of parentheses (counrty)
        const match = displayName.match(/\([^)]+\)\s\([^)]+\)\s\(([^)]+)\)/ );
      
        return match ? match[1] : '';
      };

    // Extract the username from the first set of parentheses in the displayName
    const extractUsernameFromDisplayName = (displayName) => {
        if (!displayName) {
            return ''; // Handle the case where displayName is null or undefined
        }

        // Using regular expression to extract content inside the first set of parentheses
        const match = displayName.match(/\(([^)]+)\)/);

        // Check if there is a match and return the content inside the first set of parentheses
        return match ? match[1] : displayName;
    };
      // functions for profile pic

      useEffect(() => {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user);
          } else {
            // Handle signed-out state
          }
        });
      }, []);
    
      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
                setImage(user.photoURL || ''); // Set the initial image URL
            } else {
                console.log("No user is logged in.");
            }
        });

        return () => unsubscribe();
    }, [auth]);

    const handleImageUpload = async (e) => {
      if (!user) return;
  
      const file = e.target.files[0];
      if (!file) return;
  
      // Rename storageRef to avoid conflict with the import
      const fileStorageRef = storageRef(storage, `profile-pics/${user.uid}`);
      
      try {
          await uploadBytes(fileStorageRef, file);
          const imageURL = await getDownloadURL(fileStorageRef);
  
          await updateProfile(user, { photoURL: imageURL });
          setImage(imageURL);
  
          const userRef = dbRef(database, `users/${user.uid}`);
          await update(userRef, { photoURL: imageURL });
      } catch (error) {
          console.error('Image upload error:', error);
      }
  };
  

      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setAuthUser(user);
          } else {
            setAuthUser(null);
          }
        });
    
        return () => unsubscribe();
      }, []);
    
      const userSignOut = () => {
        signOut(auth).then(() => {
          console.log('signed out');
          navigate('/login');
        }).catch(error => console.log(error));
      };

      useEffect(() => {
        if (user) {
            setCountry(extractCountryFromDisplayName(user.displayName));
        } else {
            setCountry(''); // Reset if user is undefined
        }
    }, [user]);
    
      
    useEffect(() => {
      if (userId) {
        const userRef = ref(database, `users/${userId}`);
        const unsubscribe = onValue(userRef, (snapshot) => {
          const userData = snapshot.val();
          if (userData) {
            setCountry(userData.country || '');
            
            // Format and set the joined date using the timestamp field
            const timestamp = userData.timestamp;
            if (timestamp) {
              const date = new Date(timestamp);
              setJoinedDate(date.toLocaleDateString('en-GB')); // Use any format you prefer
            } else {
              setJoinedDate('Date not available'); // Handle if timestamp is missing
            }
          }
        });
        return () => unsubscribe();
      }
    }, [userId, database]);
    

  const handleEditToggle = () => {
      setIsEditing(!isEditing); // Toggle the edit mode
  };

  const handleUpdateCountry = async (event) => {
    event.preventDefault();
    if (!userId) return;

    const userRef = ref(database, `users/${userId}`);
    const updatedData = { country }; // Only updating country

    try {
        await update(userRef, updatedData); // This will only update the country
        setSuccessMessage('Country updated successfully!');
        setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
        handleEditToggle(); // Close the edit input after saving
    } catch (error) {
        console.error('Error updating country:', error);
        setSuccessMessage('Error updating country.'); // Set error message
        setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
    }
};
  
useEffect(() => {
  if (userId) {
      const userRef = ref(database, `users/${userId}`);
      const unsubscribe = onValue(userRef, (snapshot) => {
          const userData = snapshot.val();
          if (userData) {
              setCountry(userData.country || '');
              // Set the formatted join date
              const timestamp = userData.timestamp;
              if (timestamp) {
                  const date = new Date(timestamp);
                  setJoinedDate(date.toLocaleDateString('en-GB')); // Format date as needed
              }
          }
      });
      return () => unsubscribe();
  }
}, [userId, database]);

useEffect(() => {
  if (userId) {
    const database = getDatabase();
    const userRef = ref(database, `users/${userId}`);

    const unsubscribe = onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setUserData({
          role: data.role || 'N/A',
          tokens: data.tokens || 'N/A',
          phoneNumber: data.phoneNumber || 'N/A',
        });
      } else {
        setUserData(null);
      }
    });

    return () => unsubscribe(); // Cleanup listener
  }
}, [userId]);

if (!userData) {
  return <p>Loading user details...</p>;
}

  
  return (
    <div>
       <div className='App-headerV8'>
        <Navbar user={user} profilePicture={image}/>
        {user && (
              <div>
              </div>
            )}
      <section class="bg- py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center text-light"style={{ fontFamily: 'Lato, sans-serif' }}>{t('your_profile')}</h2>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row gy-4 gy-lg-0">
      <div class="col-12 col-lg-4 col-xl-3">
        <div class="row gy-4">
        <div className="col-12">
  <div className="card widget-card border-light shadow-sm text-light bg-transparent">
    <div className="card-header purple-bg text-light text-center">
      {t('welcomep')} {extractUsernameFromDisplayName(user?.displayName)}
    </div>
    <div className="card-body">
      <div className="text-center mb-3">
        {user && (
          <div className="d-flex justify-content-center mb-2">
            <span className="text-white border border-3 border-primary-subtle rounded-circle d-flex align-items-center justify-content-center">
              {user.photoURL ? (
                <img
                  src={user.photoURL}
                  alt="Profile"
                  className="avatar default-profile-picture"
                />
              ) : (
                <img
                  src="https://t4.ftcdn.net/jpg/00/64/67/63/360_F_64676383_LdbmhiNM6Ypzb3FM4PPuFP9rHe7ri8Ju.jpg"
                  alt="Default Profile"
                  className="avatar default-profile-picture"
                />
              )}
            </span>
          </div>
        )}
      </div>
      <div className="text-center mb-3 text-light bg-transparent">
        <label className="mb-0 fs-7 text-light">
          {t('edit_profile_picture')}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload} // Ensure this function is defined correctly
            style={{ display: 'none' }} // Hides the file input
          />
        </label>
      </div>
      <h5 className="text-center mb-1">
        {extractNameOnlyFromDisplayName(user?.displayName)} {extractSurnameFromDisplayName(user?.displayName)}
      </h5>
      <div className='text-center padtopinput'>
        <Link to='/'>
          <button className='btn btn-outline-danger text-center' onClick={userSignOut}>
            {t('sign_out')}
          </button>
        </Link>
      </div>
    </div>
  </div>
</div>


<div className="col-12 bg-transparent">
  <div className="card widget-card border-light shadow-sm bg-transparent">
    <div className="card-header purple-bg text-light text-center ">{t('about_me')}</div>
    <div className="card-body bg-transparent">
      <ul className="list-group list-group-flush mb-0 bg-transparent">
        <li className="list-group-item bg-transparent">
          <h6 className="mb-1 text-light">
            <span className="bi bi-mailbox me-2 text-light"></span>Email
          </h6>
          <span className='text-light'>{user?.email}</span>
        </li>
        <li className="list-group-item text-light bg-transparent">
    <h6 className="mb-1 text-light bg-transparent">
        <span className="bii bi-geo-alt-fill me-2 text-light bg-transparent"></span>{t('location')}
    </h6>
    {isEditing ? (
                                        <form onSubmit={handleUpdateCountry} className="d-flex">
                                            <input
                                                type="text"
                                                value={country}
                                                onChange={(e) => setCountry(e.target.value)}
                                                className="form-control me-2"
                                                required
                                            />
                                            <button type="submit" className="btn btn-success">Save Changes</button>
                                        </form>
                                    ) : (
                                        <div className="d-flex align-items-center text-light bg-transparent">
                                            <span>{country}</span>
                                            <button onClick={handleEditToggle} className="btn btn-link text-primary ms-2">
                                                <FaEdit /> {/* Edit icon */}
                                            </button>
                                        </div>
                                    )}
                                                                    {successMessage && <div className="alert alert-info mt-2">{successMessage}</div>}
</li>
        <li className="list-group-item text-light bg-transparent">
          <h6 className="mb-1 text-light bg-transparent">
            <span className="bii bi-building-fill-gear me-2 text-light bg-transparent"></span>{t('date_joined')}
          </h6>
          <span className='text-light bg-transparent'>{joinedDate}</span> {/* Display the formatted join date */}
          </li>
      </ul>
      
    </div>
 
  </div>
</div>
        </div>
      </div>
      <div className="col-12 col-lg-8 col-xl-9 text-light bg-transparent">
  <div className="card widget-card border-light shadow-sm text-light bg-transparent">
    <div className="card-body p-4 text-light bg-transparent">
      <ul className="nav nav-tabs" id="profileTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="overview-tab"
            data-bs-toggle="tab"
            data-bs-target="#overview-tab-pane"
            type="button"
            role="tab"
            aria-controls="overview-tab-pane"
            aria-selected="true"
          >
            Overview
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="password-tab"
            data-bs-toggle="tab"
            data-bs-target="#password-tab-pane"
            type="button"
            role="tab"
            aria-controls="password-tab-pane"
            aria-selected="false"
          >
            Password Reset
          </button>
        </li>
      </ul>

      {/* Tab Content */}
      <div className="tab-content pt-4" id="profileTabContent">
{/* Overview Tab Content */}
<div
  className="tab-pane fade show active"
  id="overview-tab-pane"
  role="tabpanel"
  aria-labelledby="overview-tab"
  tabIndex="0"
>
  <h5 className="mb-4 text-light">Overview</h5>
  <p className="mb-4  text-light">
    This is your profile overview where you can view and edit your details.
  </p>

  {/* User Information */}
<div className="container py-3">
  <div className="row g-3">
    {/* Username */}
    <div className="col-12 col-md-6">
      <div className="list-group-item d-flex align-items-center py-3">
        <span className="bi bi-person me-3 fs-4 text-primary"></span>
        <div>
          <h6 className="mb-1 text-light">Username</h6>
          <span className="text-light">{extractUsernameFromDisplayName(user?.displayName)}</span>
        </div>
      </div>
    </div>

    {/* Email */}
    <div className="col-12 col-md-6">
      <div className="list-group-item d-flex align-items-center py-3">
        <span className="bi bi-mailbox me-3 fs-4 text-primary"></span>
        <div>
          <h6 className="mb-1 text-light">Email</h6>
          <span className="text-light">{user?.email}</span>
        </div>
      </div>
    </div>

    {/* Country */}
    <div className="col-12 col-md-6">
      <div className="list-group-item d-flex align-items-center py-3">
        <span className="bi bi-globe me-3 fs-4 text-primary"></span>
        <div>
          <h6 className="mb-1 text-light">Country</h6>
          <span className="text-light">{country}</span>
        </div>
      </div>
    </div>

    {/* First Name */}
    <div className="col-12 col-md-6">
      <div className="list-group-item d-flex align-items-center py-3">
        <span className="bi bi-person-fill me-3 fs-4 text-primary"></span>
        <div>
          <h6 className="mb-1 text-light">First Name</h6>
          <span className="text-light">{extractNameOnlyFromDisplayName(user?.displayName)}</span>
        </div>
      </div>
    </div>

    {/* Last Name */}
    <div className="col-12 col-md-6">
      <div className="list-group-item d-flex align-items-center py-3">
        <span className="bi bi-person-video2 me-3 fs-4 text-primary"></span>
        <div>
          <h6 className="mb-1 text-light">Last Name</h6>
          <span className="text-light">{extractSurnameFromDisplayName(user?.displayName)}</span>
        </div>
      </div>
    </div>

    {/* Phone Number */}
    <div className="col-12 col-md-6">
      <div className="list-group-item d-flex align-items-center py-3">
        <span className="bi bi-phone me-3 fs-4 text-primary"></span>
        <div>
          <h6 className="mb-1 text-light">Phone Number</h6>
          <span className="text-light">{userData.phoneNumber || 'Not Available'}</span>
        </div>
      </div>
    </div>

  </div>
</div>


  {/* You can add more detailed information or controls here as needed */}
</div>

        
        {/* Password Reset Tab Content */}
        <div
          className="tab-pane fade"
          id="password-tab-pane"
          role="tabpanel"
          aria-labelledby="password-tab"
          tabindex="1"
        >
          <PassResetProfile />
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  </div>

</section>
        <Footer />
</div>
        </div>
  )
}

export default UserProfile