import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Bournemouth from '../Team-Kits-Images/Bournemouth.png'
import Brentford from '../Team-Kits-Images/Brentford.png'
import Brighton from '../Team-Kits-Images/Brighton.png'
import Burnley from '../Team-Kits-Images/Burnley.png'
import Chelsea from '../Team-Kits-Images/Chelsea.png'
import CrystalPalace from '../Team-Kits-Images/CrystalPalace.png'
import Everton from '../Team-Kits-Images/Everton.png'
import Fulham from '../Team-Kits-Images/Fulham.png'
import Ipswich from '../Team-Kits-Images/Ipswitch.png'
import Leicester from '../Team-Kits-Images/Leicester.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import Luton from '../Team-Kits-Images/LutonTown.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import ManUnited from '../Team-Kits-Images/Man.United.png'
import Newcastle from '../Team-Kits-Images/Newcastle.png'
import NottinghamForest from '../Team-Kits-Images/NottinghamForest.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Southampton from '../Team-Kits-Images/Southampton.png'
import Tottenham from '../Team-Kits-Images/Tottenham.png'
import WestHam from '../Team-Kits-Images/WestHam.png'
import Wolves from '../Team-Kits-Images/Wolves.png'
import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'
import '../App.css';

function Standings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
      case 'Arsenal':
        return Arsenal;
        case 'Aston Villa':
          return AstonVilla;
        case 'Bournemouth':
          return Bournemouth;
        case 'Brentford':
          return Brentford;
        case 'Brighton':
          return Brighton;
        case 'Burnley':
          return Burnley;
        case 'Chelsea':
          return Chelsea;
        case 'Crystal Palace':
          return CrystalPalace;
        case 'Everton':
          return Everton;
        case 'Fulham':
          return Fulham;
        case 'Ipswich':
          return Ipswich;
        case 'Leicester':
          return Leicester;
        case 'Liverpool':
          return Liverpool;
        case 'Luton':
          return Luton;
        case 'Manchester City':
          return ManCity;
        case 'Manchester United':
          return ManUnited;
        case 'Newcastle':
          return Newcastle;
        case 'Nottingham Forest':
          return NottinghamForest;
        case 'Sheffield United':
          return SheffieldUnited;
        case 'Southampton':
          return Southampton;
        case 'Tottenham':
          return Tottenham;
        case 'West Ham':
          return WestHam;
        case 'Wolves':
          return Wolves;
      default:
        return null;
    }
  };

  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>EPL Standings</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*Click on team name to view stats </i>
    </div>

    <div className='d-flex justify-content-center'>
  <div className='col-10 col-md-6 col-lg-4'>
    <select
      className='form-select mb-3 text-center'
      style={{
        backgroundColor: 'purple',
        color: 'white',
        textAlign: 'center', // Center the text
        textAlignLast: 'center', // Center the selected option
      }}
      value={season}
      onChange={(e) => setSeason(e.target.value)}
    >
      <option className='text-center' value="2024" style={{ backgroundColor: 'white', color: 'black' }}>
        EPL 24/25
      </option>
      <option className='text-center' value="2023" style={{ backgroundColor: 'white', color: 'black' }}>
        EPL 23/24
      </option>
      <option className='text-center' value="2022" style={{ backgroundColor: 'white', color: 'black' }}>
        EPL 22/23
      </option>
      <option className='text-center' value="2021" style={{ backgroundColor: 'white', color: 'black' }}>
        EPL 21/22
      </option>
      <option className='text-center' value="2020" style={{ backgroundColor: 'white', color: 'black' }}>
        EPL 20/21
      </option>
    </select>
  </div>
</div>


    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
          <table
            className="table transparent-table"
            style={{
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
                <th scope="col" style={{ width: '5%', color: 'white' }}>#</th>
                <th scope="col" className="team-column text-light">Team</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>MP</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>W</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>D</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>L</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>Pts</th>
                <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>Last 5</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
                          <th scope="row" className={
    index < 4 ? 'text-primary' :
    index >= standings.length - 3 ? 'text-danger' :
    index === 6 ? 'text-success' :  // 7th position (index 6)
    index >= 4 && index <= 5 ? 'text-warning' : 'text-light'}>
    {index + 1}
</th>
                  <td style={{ color: 'white' }}>
                    {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                    {team.team === 'Manchester United' ? (
                      <Link to="/manchesterunited" style={{ textDecoration: 'none', color: 'white' }}>
                        Man Utd
                      </Link>
                    ) : team.team === 'Arsenal' ? (
                      <Link to="/arsenal" style={{ textDecoration: 'none', color: 'white' }}>Arsenal</Link>
                    ) : team.team === 'Manchester City' ? (
                      <Link to="/mancity" style={{ textDecoration: 'none', color: 'white' }}>Man City</Link>
                    ) : team.team === 'Liverpool' ? (
                      <Link to="/liverpool" style={{ textDecoration: 'none', color: 'white' }}>Liverpool</Link>
                    ) : team.team === 'Aston Villa' ? (
                      <Link to="/astonvilla" style={{ textDecoration: 'none', color: 'white' }}>Aston Villa</Link>
                    ) : team.team === 'Tottenham' ? (
                      <Link to="/tottenham" style={{ textDecoration: 'none', color: 'white' }}>Tottenham</Link>
                    ) : team.team === 'Chelsea' ? (
                      <Link to="/chelsea" style={{ textDecoration: 'none', color: 'white' }}>Chelsea</Link>
                    ) : team.team === 'Newcastle' ? (
                      <Link to="/newcastle" style={{ textDecoration: 'none', color: 'white' }}>Newcastle</Link>
                    ) : team.team === 'West Ham' ? (
                      <Link to="/westham" style={{ textDecoration: 'none', color: 'white' }}>West Ham</Link>
                    ) : team.team === 'Crystal Palace' ? (
                      <Link to="/crystalpalace" style={{ textDecoration: 'none', color: 'white' }}>C. Palace</Link>
                    ) : team.team === 'Bournemouth' ? (
                      <Link to="/bournemouth" style={{ textDecoration: 'none', color: 'white' }}>Bourne...</Link>
                    ) : team.team === 'Brighton' ? (
                      <Link to="/brighton" style={{ textDecoration: 'none', color: 'white' }}>Brighton</Link>
                    ) : team.team === 'Everton' ? (
                      <Link to="/everton" style={{ textDecoration: 'none', color: 'white' }}>Everton</Link>
                    ) : team.team === 'Fulham' ? (
                      <Link to="/fulham" style={{ textDecoration: 'none', color: 'white' }}>Fulham</Link>
                    ) : team.team === 'Wolves' ? (
                      <Link to="/wolves" style={{ textDecoration: 'none', color: 'white' }}>Wolves</Link>
                    ) : team.team === 'Brentford' ? (
                      <Link to="/brentford" style={{ textDecoration: 'none', color: 'white' }}>Brentford</Link>
                    ) : team.team === 'Nottingham Forest' ? (
                      <Link to="/nottinghamforest" style={{ textDecoration: 'none', color: 'white' }}>Nott. Forest</Link>
                    ) : team.team === 'Luton' ? (
                      <Link to="/luton" style={{ textDecoration: 'none', color: 'white' }}>Luton</Link>
                    ) : team.team === 'Burnley' ? (
                      <Link to="/burnley" style={{ textDecoration: 'none', color: 'white' }}>Burnley</Link>
                    ) : team.team === 'Sheffield Utd' ? (
                      <Link to="/sheffieldutd" style={{ textDecoration: 'none', color: 'white' }}>Sheffield</Link>
                    ) : team.team === 'Leicester' ? (
                      <Link to="/Leicester" style={{ textDecoration: 'none', color: 'white' }}>Leicester</Link>
                    ) : team.team === 'Ipswich' ? (
                      <Link to="/Ipswich" style={{ textDecoration: 'none', color: 'white' }}>Ipswich</Link>
                    ) : team.team === 'Southampton' ? (
                      <Link to="/Southampton" style={{ textDecoration: 'none', color: 'white' }}>Southam...</Link>
                    ) : (
                      team.team
                    )}
                  </td>
                  <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                  <td style={{ color: 'white' }}>{team.wins}</td>
                  <td style={{ color: 'white' }}>{team.draws}</td>
                  <td style={{ color: 'white' }}>{team.losses}</td>
                  <td style={{ color: 'white' }}>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}
    <div className="container mt-3 mx-auto">
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-primary me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">UEFA Champions League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-warning me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa League Qualification</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-success me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Europa Conference League Qualification</div>
        </div>
        <div className="d-flex align-items-center" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-danger me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">Relegation</div>
        </div>
      </div>
    </div>

    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  <Footer />
  </div>
</div>


  );
}

export default Standings;
